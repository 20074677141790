<script lang='ts'>
	import { fade } from 'svelte/transition';
</script>

<div class='overlay' transition:fade></div>

<style lang='scss'>
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $overlay;
  }
</style>
