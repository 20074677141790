<script>
	import Image from '@/lib/components/Image.svelte';
</script>

<div class='cover'>
	<Image src='/cover.png' />
</div>

<style lang='scss'>
  .cover {
    width: 100%;
    height: 426px;
    position: relative;
    display: flex;
    flex-shrink: 0;
    background: $yellow;
    overflow: hidden;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(600px);
    }

    @include desktop-breakpoint-up {
      height: 426px;
    }

    :global(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include breakpoint-up($tablet) {
        width: auto;
      }

      @include breakpoint-up($tablet) {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:after {
      height: 100px;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      background: linear-gradient(180deg, rgba(253, 248, 235, 0) 0%, $beige-a 85.5%);
    }
  }
</style>
