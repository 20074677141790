<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 28 32'>
	<path fill='#FF335F'
				d='M27 14.268c1.333.77 1.333 2.694 0 3.464L3 31.589c-1.333.77-3-.193-3-1.733V2.144C0 .604 1.667-.358 3 .412l24 13.856Z' />
</svg>

<style lang='scss'>
  svg {
    width: 40px;
    height: 40px;
    position: relative;
    left: 3px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(70px);
      height: px-to-rem-tablet(70px);
      left: px-to-rem-tablet(6px);
    }
  }
</style>
