<script lang='ts'>
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Copy from '@/assets/Copy.svelte';

	export let videoID;
	export let style = null;
	let link;
	let interval;
	let copied = false;

	$:link = `${import.meta.env.VITE_FRONTEND_URL}/m/${videoID}`;

	const copy = async () => {
		await navigator.clipboard.writeText(link);
		copied = true;
		interval && clearTimeout(interval);
		interval = setTimeout(() => {
			copied = false;
		}, 2000);
	};
</script>

<div class='share-link' {style}>
	<slot />

	{#if !$$slots.default}
		<Text -popup-caption -bold>
			{@html texts.promo.share}
		</Text>
	{/if}

	<button disabled='{!videoID}' on:click={copy}>
		<span class='link'>
			{videoID ? link : 'Загрузка...'}
		</span>
		{#if videoID}
			<Copy />
		{/if}
	</button>
</div>

<style lang='scss'>
  button {
    @include reset-button;

    width: 100%;
    height: 60px;
    max-width: 353px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: $gray-b;
    box-sizing: border-box;
    background: $white;
    border-radius: 16px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(620px);
      height: px-to-rem-tablet(105px);
      max-width: 100%;
      font-size: px-to-rem-tablet(28px);
    }

    &:disabled {
      pointer-events: none;

      > .text {
        opacity: 0.35;
      }
    }
  }

  .link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
