<script lang='ts'>
	import texts from '@/texts.js';
	import Popup from '@/lib/components/Popup.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import Button from '@/lib/components/Button.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';

	const restart = () => {
		eventBus.emit(EVENTS.RESTART_MICROPHONE_TEST);
		eventBus.emit(EVENTS.RESTART_RECORD);
	};
</script>

<Popup class='microphone-test-failed'>
	<div class='_title'>
		<Text -popup-title -center -bold>
			{@html texts.popup.testFailed.title}
		</Text>
	</div>
	<div class='_caption'>
		<Text -popup-caption -center>
			{@html texts.popup.testFailed.caption}
		</Text>
	</div>
	<div class='_button'>
		<Button -small on:click={restart}>{@html texts.popup.testFailed.button}</Button>
	</div>
</Popup>

<style lang='scss'>
  ._caption {
    width: 286px;
    margin-top: 18px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-up($tablet) {
      width: 100%;
    }
  }

  ._button {
    width: 100%;
    margin-top: 55px;
  }
</style>
