<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import Container from '@/lib/components/Container.svelte';
	import Promocode from '@/lib/components/Promocode.svelte';
	import { currentVideoIndex, sharedQRData } from '@/stores';
	import ShareLink from '@/lib/components/ShareLink.svelte';
	import Button from '@/lib/components/Button.svelte';
	import Legal from '@/lib/components/Legal.svelte';
	import MadeByGroznov from '@/lib/components/MadeByGroznov.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Image from '@/lib/components/Image.svelte';
	import videoConfig from '@/video.config';
	import { get } from 'svelte/store';

	const goToVideo = () => {
		window.open(`${import.meta.env.VITE_FRONTEND_URL}/m/${$sharedQRData.id}`, '_blank');
	};
</script>

<Screen>
	<div class='cover'>
		<Image src='{videoConfig.video[get(currentVideoIndex)].preview[4]}' />
	</div>
	<Container -grow-max>
		<Text -bold -popup-title>{@html texts.sharedQR.title}</Text>
		<Promocode code='МУЛЬТФИЛЬМ' />
		<div class='_button'>
			<Button -small>В самокат</Button>
		</div>
		<div class='_link'>
			<ShareLink videoID='{$sharedQRData.id}' />
		</div>
		<div class='_button2'>
			<Button -small on:click={goToVideo}>Перейти</Button>
		</div>
		<div class='_legal'>
			<Legal />
		</div>
		<div class='_made'>
			<MadeByGroznov />
		</div>
	</Container>
</Screen>

<style lang='scss'>
  .cover {
    width: 100%;
    height: 229px;
    margin-bottom: 16px;
    flex-shrink: 0;
    border: 1px solid $black;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(531px);
      margin-bottom: px-to-rem-tablet(50px);
    }

    @include desktop-breakpoint-up {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    :global(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ._link {
    margin-top: 60px;

    @include breakpoint-up($tablet) {
      margin-top: 100px;
    }
  }

  ._button {
    margin-top: 24px;

    @include breakpoint-up($tablet) {
      margin-top: 35px;
    }
  }

  ._button2 {
    margin-top: 20px;
    margin-bottom: 60px;

    @include breakpoint-up($tablet) {
      margin-top: 40px;
      margin-bottom: 117px;
    }
  }

  ._legal {
    margin-top: auto;
  }

  ._made {
    margin-top: 1rem;
    margin-bottom: 16px;

    @include breakpoint-up($tablet) {
      margin-bottom: 50px;
    }
  }
</style>
