<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 353 56'>
	<path fill='#F9F3E5' fill-rule='evenodd'
				d='M0 5a5 5 0 0 1 5-5h343a5 5 0 0 1 5 5v51H0V5Zm37 23c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Zm33 10.004c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10ZM123 28c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Zm33 10.004c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10ZM209 28c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Zm33 10.004c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10ZM295 28c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Zm33 10c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z'
				clip-rule='evenodd' />
	<circle cx='27' cy='28' r='10' stroke='#000' />
	<circle cx='70' cy='28' r='10' stroke='#000' />
	<circle cx='113' cy='28' r='10' stroke='#000' />
	<circle cx='156' cy='28' r='10' stroke='#000' />
	<circle cx='199' cy='28' r='10' stroke='#000' />
	<circle cx='242' cy='28' r='10' stroke='#000' />
	<circle cx='285' cy='28' r='10' stroke='#000' />
	<circle cx='328' cy='28' r='10' stroke='#000' />
</svg>

<style lang='scss'>
  svg {
    width: calc(100% + 4px);
    position: relative;
    left: -2px;
  }
</style>
