<script lang='ts'>
	import Popup from '@/lib/components/Popup.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import ShareLink from '@/lib/components/ShareLink.svelte';
	import { currentVideoIndex, sharedVideoLinks } from '@/stores';
	import Button from '@/lib/components/Button.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import texts from '@/texts';

	const back = () => {
		eventBus.emit(EVENTS.SHARE_POPUP, false);
		eventBus.emit(EVENTS.VIDEO_RESUME);
	};
</script>

<Popup withTappedOverlay>
	<ShareLink style='margin-top: 0;' videoID='{$sharedVideoLinks.get($currentVideoIndex)}'>
		<Text -center -bold -popup-caption>{@html texts.popup.share.title}</Text>
	</ShareLink>
	<div class='_button'>
		<Button -small on:click={back}>Назад</Button>
	</div>
</Popup>

<style lang='scss'>
  ._button {
    margin-top: 43px;
  }
</style>
