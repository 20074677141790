<script lang='ts'>
	import SoundToggle from '@/lib/components/SoundToggle.svelte';
	import Container from '@/lib/components/Container.svelte';
	import MenuButton from '@/lib/components/menu/MenuButton.svelte';
	import { isOnlineVersion } from '@/stores';
</script>

<div class='header'>
	<Container class='header-container' horizontal>
		<SoundToggle />
		{#if $isOnlineVersion}
			<MenuButton />
		{/if}
	</Container>
</div>

<style lang='scss'>
  .header {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;

    :global(.header-container) {
      padding-left: 28px;
      padding-right: 28px;

      @include breakpoint-up($tablet) {
        padding-left: 100px;
        padding-right: 100px;
      }

      @include desktop-breakpoint-up {
        padding-left: 22px;
        padding-right: 22px;
      }
    }

    @include breakpoint-up($tablet) {
      top: px-to-rem-tablet(35px);
    }
  }
</style>
