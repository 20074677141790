import { uid } from 'uid';
import type { DefaultEventMap, EventKey, EventMap } from '@/types';
import type { EVENTS } from '@/enums';

export default class EventEmitter<
	TEventMap extends EventMap<EVENTS> = DefaultEventMap,
> extends EventTarget {
	readonly uid!: string;

	private pipes: Map<string, EventEmitter<TEventMap>> = new Map();

	constructor() {
		super();

		this.uid = uid();
	}

	on<TEventName extends EventKey<TEventMap>>(
		eventName: TEventName,
		handler: TEventMap[TEventName],
	) {
		const handlerWithArgs = (evt: CustomEvent) => (<(...any: any[]) => void>handler)(...evt.detail);
		super.addEventListener(eventName, <EventListener>handlerWithArgs);

		return () => super.removeEventListener(eventName, <EventListener>handlerWithArgs);
	}

	once<TEventName extends EventKey<TEventMap>>(
		eventName: TEventName,
		handler: TEventMap[TEventName],
	) {
		const handlerWithArgs = (evt: CustomEvent) => (<(...any: any[]) => void>handler)(...evt.detail);
		super.addEventListener(eventName, <EventListener>handlerWithArgs, {
			once: true,
		});

		return () => super.removeEventListener(eventName, <EventListener>handlerWithArgs);
	}

	emit(type: EVENTS, ...args: any[]) {
		for (let emitter of this.pipes.values()) {
			emitter.emit(type, ...args);
		}

		super.dispatchEvent(
			new CustomEvent(type, {
				detail: args,
			}),
		);
	}

	pipe(target: EventEmitter<TEventMap>) {
		this.pipes.set(target.uid, target);
	}

	unpipe(target: EventEmitter<TEventMap>) {
		this.pipes.delete(target.uid);
	}
}
