<script lang='ts'>
	import { fade } from 'svelte/transition';
	import videoConfig from '@/video.config.js';
	import { currentVideoIndex, currentVideoPart } from '@/stores.js';
	import Text from '@/lib/components/typo/Text.svelte';
	import { get } from 'svelte/store';
	import Done from '@/assets/Done.svelte';
	import tp from '@/typo';
</script>

<div class='video-part-progress' transition:fade>
	<div class='icon'>
		<Done />
	</div>
	<div class='_text'>
		<Text -popup-title -center -white>
			{@html tp.execute(`${get(currentVideoPart) + 1} из ${videoConfig.video[$currentVideoIndex].regions.length} фрагментов - записали`)}
		</Text>
	</div>
</div>

<style lang='scss'>
  .video-part-progress {
    margin: 0 auto;
    position: absolute;
    top: 27px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    width: 94px;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $white;
    border-radius: 94px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(165px);
      height: px-to-rem-tablet(165px);
      border-radius: px-to-rem-tablet(94px);
    }
  }

  ._text {
    width: 277px;
    margin-top: 25px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(400px);
      margin-top: px-to-rem-tablet(40px);
    }
  }
</style>
