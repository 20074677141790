<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import VideoPreview from '@/lib/components/VideoPreview.svelte';
	import Container from '@/lib/components/Container.svelte';
	import videoConfig from '@/video.config';
	import Title from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Header from '@/lib/components/Header.svelte';
	import Film from '@/lib/components/Film.svelte';
</script>

<Screen class='select-video-screen' bg-gray>
	<div class='film-wrapper'>
		<Film />
	</div>
	<div class='film-wrapper'>
		<Film />
	</div>
	<Container class='select-video-container' -grow-max>
		<Header />
		<div class='_title'>
			<Title -header-title -white -center>{@html texts.selectVideo.title}</Title>
		</div>
		<div class='videos'>
			{#each videoConfig.video as video, index}
				<VideoPreview {video} {index} />
			{/each}
		</div>
	</Container>
</Screen>

<style lang='scss'>
  :global(.select-video-screen) {
    @include desktop-breakpoint-up {
      width: 700px;
    }
  }

  :global(.select-video-container) {
    position: relative;

    @include breakpoint-up($tablet) {
      width: 100% !important;
      max-width: 1200px;
      padding-left: 100px !important;
      padding-right: 100px !important;
    }

    @include desktop-breakpoint-up {
      width: 544px !important;
      padding-left: 22px !important;
      padding-right: 22px !important;
    }
  }

  .videos {
    width: 100%;
    margin-top: 22px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 32px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 28px;
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      padding-left: 0;
      padding-right: 0;
      margin-top: px-to-rem-tablet(65px);
      row-gap: px-to-rem-tablet(72px);
    }
  }

  ._title {
    width: 220px;
    margin: 20px auto 0;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(600px);
      margin-top: px-to-rem-tablet(40px);
    }
  }

  .film-wrapper {
    --xPos: 10px;

    position: fixed;
    top: 8px;
    bottom: 0;
    left: var(--xPos);

    @include breakpoint-up($tablet) {
      --xPos: 20px;
    }

    @include desktop-breakpoint-up {
      --xPos: calc(50% + 310px - 20px);
    }

    &:nth-child(2) {
      left: auto;
      right: var(--xPos);
    }
  }
</style>
