<script lang='ts'>
	import { fade } from 'svelte/transition';

	export let style = null;
	export let reverse = false;
</script>

<div {style} class='recording-info-background' class:-reverse={reverse} transition:fade></div>

<style lang='scss'>
  .recording-info-background {
    height: 350px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0532679) 4.1%, rgba(0, 0, 0, 0.165243) 8.68%, rgba(0, 0, 0, 0.365194) 15.86%, rgba(0, 0, 0, 0.532323) 22.82%, rgba(0, 0, 0, 0.614844) 27.29%, rgba(0, 0, 0, 0.721707) 34.58%, rgba(0, 0, 0, 0.7905) 41.48%, rgba(0, 0, 0, 0.8245) 47.36%, rgba(0, 0, 0, 0.837035) 50.89%, rgba(0, 0, 0, 0.85) 56.67%);
    transform: rotate(-180deg);
  }
</style>
