<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 30 47'>
	<rect width='16' height='30' x='7' fill='#FF335F' rx='8' />
	<path stroke='#FF335F' stroke-linecap='round' stroke-width='3'
				d='M28 15v7c0 7.18-5.82 13-13 13v0C7.82 35 2 29.18 2 22v-7' />
	<path stroke='#FF335F' stroke-width='3' d='M15 36v8M15 36v8' />
	<path stroke='#FF335F' stroke-linecap='round' stroke-width='3' d='M7 45.5h16M7 45.5h16' />
</svg>

<style lang='scss'>
  svg {
    width: 30px;
    height: 47px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(45px);
      height: px-to-rem-tablet(80px);
    }
  }
</style>
