import tp from '@/typo';

export default {
	video: [
		{
			src: '/video.mp4',
			title: tp.execute('Легко наверх'),
			snack: '/snack3.png',
			preview: ['/preview1.png'],
			menuPreview: '/menu-preview1.jpg',
			regions: [
				{
					start: 13.7,
					finish: 15.7,
					caption: tp.execute('Постучите по упаковке, когда герой подойдёт к двери'),
				},
				{
					start: 21.6,
					finish: 23.5,
					caption: tp.execute('Сделайте громкий глоток сока, когда герой посмотрит наверх'),
				},
				{
					start: 29.3,
					finish: 31.6,
					caption: tp.execute(
						'Побулькайте соком через трубочку, когда герой начнёт надувать мыльные пузыри',
					),
				},
				{
					start: 37.3,
					finish: 39.3,
					caption: tp.execute('Громко пейте сок вместе с принцессой'),
				},
			],
		},
		{
			src: '/video2.mp4',
			title: tp.execute('Кто живёт в берлоге?'),
			preview: ['/preview2.png'],
			snack: '/snack1.png',
			menuPreview: '/menu-preview2.jpg',
			regions: [
				{
					start: 11.6,
					finish: 13.6,
					caption: tp.execute('Пошуршите упаковкой, когда герой поедет через ручей'),
				},
				{
					start: 18.7,
					finish: 20.7,
					caption: tp.execute(
						'Возьмите пару мармеладок и почавкайте ими, когда герой будет пробираться через болото',
					),
				},
				{
					start: 53.6,
					finish: 57.5,
					caption: tp.execute('Радостно поешьте мармелад вместе с зайцем Мишей'),
				},
			],
		},
		{
			src: '/video3.mp4',
			title: tp.execute('Хруст — и в зиму'),
			preview: ['/preview3.png'],
			snack: '/snack2.png',
			menuPreview: '/menu-preview3.jpg',
			regions: [
				{
					start: 3.0,
					finish: 5.5,
					caption: tp.execute('Пошуршите упаковкой, когда герой поедет по листьям'),
				},
				{
					start: 13.2,
					finish: 15.5,
					caption: tp.execute('Похрустите ломтиком чипсов вместе с героем'),
				},
				{
					start: 20.0,
					finish: 23.0,
					caption: tp.execute('Похрустите вместе с героем ещё раз'),
				},
				{
					start: 29.5,
					finish: 32.3,
					caption: tp.execute('Съешьте пару чипсов, когда герой пойдёт по снегу'),
				},
			],
		},
	],
};
