<script lang='ts'>
	let className = '';

	export let style = null;
	export let disabled = null;
	export { className as class };

	const filterByTypoClasses = (list: string[]) => {
		return list.filter(typo => ['-.+'].map(value => !!typo.match(value)).includes(true));
	};

	const typoClasses = filterByTypoClasses(Object.keys($$restProps)).join(' ');

	const classes = `${className} ${typoClasses}`.trim();
</script>

<button class='_button {classes}' {disabled} on:click {style}>
	<slot />
</button>

<style lang='scss'>
  ._button {
    @include reset-button;

    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: $white;
    border-radius: 40px;
    background-color: $red-a;
    pointer-events: auto;
    box-sizing: border-box;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &.-hollow {
      background-color: transparent;
      border: 2px solid $red-a;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: $red-a;
    }

    &.-big {
      height: 64px;
      border-radius: 64px;
    }

    &.-small {
      height: 56px;

      @include breakpoint-up($tablet) {
        height: 112px;
        @include tablet-font-size(40px);
        border-radius: 112px;
      }

      @include desktop-breakpoint-up() {
        height: 56px;
      }
    }
  }
</style>
