<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import VideoPlayer from '@/lib/components/VideoPlayer.svelte';
	import { onMount } from 'svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import FinalVideoMenu from '@/lib/components/menu/FinalVideoMenu.svelte';
	import type Plyr from 'plyr';
	import { finalVideoMenu } from '@/stores';

	let player: Plyr;
	let timeoutId;

	function isTouchPointer() {
		return matchMedia('(pointer: coarse)').matches;
	}

	const menuToggle = () => {
		clearTimeout(timeoutId);
		eventBus.emit(EVENTS.FINAL_VIDEO_MENU, !$finalVideoMenu);

		if ($finalVideoMenu) timeoutId = setTimeout(() => eventBus.emit(EVENTS.FINAL_VIDEO_MENU, false), 3000);
	};

	onMount(() => {
		player.on('ended', () => eventBus.emit(EVENTS.FINAL_VIDEO_MENU, true));
		eventBus.emit(EVENTS.VIDEO_START);
	});
</script>

<Screen bg-gray>
	<VideoPlayer
		on:click={()=>isTouchPointer() && menuToggle()}
		on:mousemove={()=>{!$finalVideoMenu && !isTouchPointer() && menuToggle()}}
		bind:player={player}
		final />

	{#if $finalVideoMenu}
		<FinalVideoMenu />
	{/if}
</Screen>
