<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 44 44'>
	<path fill='#F2F2F2' d='M44 22c0 12.15-9.85 22-22 22S0 34.15 0 22 9.85 0 22 0s22 9.85 22 22Z' />
	<path stroke='#1E1E1E' stroke-linecap='round' stroke-width='2'
				d='M23.5 16.308V16a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h.308' />
	<path fill='#F2F2F2' stroke='#1E1E1E' stroke-width='2'
				d='M19.5 22a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-6Z' />
</svg>

<style lang='scss'>
  svg {
    width: 44px;
    height: 44px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(77px);
      height: px-to-rem-tablet(77px);
    }
  }
</style>
