<script lang='ts'>
	export let horizontal = false;
	export let style = null;
	let classList = '';

	export { classList as class };

	const filterByTypoClasses = (list: string[]) => {
		return list.filter(typo => ['-.+'].map(value => !!typo.match(value)).includes(true));
	};

	const typoClasses = filterByTypoClasses(Object.keys($$restProps)).join(' ');

	const classes = `${classList} ${typoClasses}`.trim();
</script>

<div class='container {classes}' class:-hor={horizontal} {style}>
	<slot />
</div>

<style lang='scss'>
  .container {
    padding: 0 22px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      width: 1044px;
      margin-left: auto;
      margin-right: auto;
    }

    @include desktop-breakpoint-up() {
      width: 544px;
    }

    &.-hor {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &.-grow-max {
      flex-grow: 1;
    }
  }
</style>
