<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 31'>
	<path stroke='#FF335F' stroke-linecap='round' stroke-width='3' d='M34 15.64H3' />
	<path stroke='#FF335F' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'
				d='M16.142 1.998 2.43 15.711l13.712 13.712' />
</svg>

<style lang='scss'>
  svg {
    width: 36px;
    height: 31px;

    @include breakpoint-up($tablet) {
      width: 56px;
      height: 48px;
    }
  }
</style>
