<script lang='ts'>

	import Logo from '@/assets/Logo.svelte';
</script>

<div class='logo'>
	<Logo />
</div>

<style lang='scss'>
  .logo {
    width: 165px;
    height: 49px;
    margin: auto;
    position: absolute;
    top: 33px;
    left: 0;
    right: 0;

    @include breakpoint-up($tablet) {
      width: 247px;
      height: 42px;
    }

    @include desktop-breakpoint-up() {
      width: 124px;
      height: 37px;
    }
  }
</style>
