<script lang='ts'>
	import { onDestroy, onMount } from 'svelte';
	import Image from '@/lib/components/Image.svelte';

	export let images: string[] = [];
	let index = 0;
	let intID;

	const nextSlide = () => {
		index = ++index % images.length;
	};

	onMount(() => {
		intID = setInterval(nextSlide, 3000);
	});

	onDestroy(() => {
		clearInterval(intID);
	});
</script>

{#if images.length}
	<div class='image-slides'>
		{#key index}
			{#each images as img, i}
				<Image src='{img}' class='{index===i ? "-visible" : ""}' />
			{/each}
		{/key}
	</div>
{/if}

<style lang='scss'>
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }

  .image-slides {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;

    :global(img) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      animation: scale 3s linear forwards;
      transform-origin: 50% 0;
      visibility: hidden;
      z-index: 0;

      @include desktop-breakpoint-up {
        height: auto;
      }
    }

    :global(img.-visible) {
      visibility: visible;
    }
  }
</style>
