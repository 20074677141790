<script lang='ts'>
	import RoundButton from '@/lib/components/RoundButton.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import { getCurrentRegionData } from '@/utils';
	import Micro from '@/assets/Micro.svelte';

	const startCountDown = () => {
		eventBus.emit(EVENTS.VIDEO_RESUME);
		eventBus.emit(EVENTS.IN_RECORD_REGION, true);
		eventBus.emit(EVENTS.PRE_RECORD_OVERLAY, false);
	};
</script>

<div class='pre-record-overlay'>
	<div class='_text'>
		<Text -center -white -popup-title -bold>{@html getCurrentRegionData().caption}</Text>
	</div>
	<div class='_button'>
		<RoundButton big on:click|once={startCountDown}>
			<Micro />
			<Text -header-title2 -center -white slot='text'>Мотор!</Text>
		</RoundButton>
	</div>
</div>

<style lang='scss'>
  .pre-record-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ._text {
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 18%;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(577px);
      top: 39%;
    }
  }

  ._button {
    position: absolute;
    bottom: 15%;

    @include breakpoint-up($tablet) {
      bottom: 11.5%;
    }
  }
</style>
