<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 50 50'>
	<path fill='#FF335F' fill-rule='evenodd'
				d='m35.623 17.352-1.377 2.042c.312.896.6 1.83.859 2.797 1.166 4.352 1.493 8.44 1.113 11.513-.19 1.54-.552 2.772-1.033 3.648-.48.872-1.028 1.312-1.582 1.46-.555.149-1.25.043-2.101-.474-.854-.517-1.784-1.404-2.718-2.642a22.249 22.249 0 0 1-1.74-2.724c1.005-.653 1.658-1.782 2-3.014.27-.981.37-2.1.29-3.277l-4.416 6.549c.383.746.783 1.45 1.194 2.106l-1.32-.74c-.194-.108-.383-.22-.568-.336l-1.119 1.659c.231.146.468.287.71.423l6.737 3.77c.04.023.08.042.122.059 1.09.61 2.275.887 3.446.573 1.247-.334 2.173-1.255 2.818-2.43.643-1.172 1.055-2.674 1.265-4.364.419-3.386.05-7.739-1.167-12.277a41.131 41.131 0 0 0-1.413-4.321Zm-19.655 18.42c-1.286-.043-2.578-.423-3.634-1.284-1.245-1.016-2.4-2.541-3.153-5.348a1.013 1.013 0 0 1-.007-.027c-.744-2.794-.505-4.686.064-6.184.782-2.06 2.724-3.338 4.643-3.852l2.92-.782c.064-.017.127-.028.19-.032.325-.917.744-1.815 1.26-2.683l3.95-6.634c.023-.04.05-.079.078-.114.639-1.073 1.526-1.903 2.696-2.217 1.247-.334 2.51 0 3.655.696 1.144.693 2.252 1.787 3.278 3.146.195.26.389.53.58.81l-1.212 1.798a20.057 20.057 0 0 0-.964-1.402c-.935-1.238-1.864-2.124-2.718-2.642-.852-.516-1.547-.623-2.101-.474-.554.148-1.103.588-1.582 1.46-.481.876-.843 2.109-1.033 3.648-.15 1.203-.19 2.562-.114 4.032 1.265 0 2.465.672 3.424 1.615.175.173.346.357.511.553l-1.175 1.743a6.732 6.732 0 0 0-.739-.87c-.674-.663-1.311-.972-1.85-1.029.166 1.444.437 2.965.816 4.529l-1.485 2.202c-.071-.246-.14-.494-.207-.744-1-3.731-1.427-7.337-1.315-10.384l-.774 1.3c-2.522 4.237-2.354 9.314-.022 13.267l-1.221 1.81a15.053 15.053 0 0 1-2.331-11.205l-1.998.535c-1.521.408-2.808 1.36-3.29 2.63-.409 1.073-.65 2.54.005 4.98l.006.025c.654 2.425 1.592 3.57 2.48 4.294.967.79 2.39 1.006 3.795.719l-1.426 2.115ZM14.985 42.434a1 1 0 0 1-.27-1.388L36.482 8.758a1 1 0 1 1 1.659 1.118L16.374 42.164a1 1 0 0 1-1.389.27Z'
				clip-rule='evenodd' />
</svg>

<style lang='scss'>
  svg {
    width: 50px;
    height: 50px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(100px);
      height: px-to-rem-tablet(100px);
    }
  }
</style>
