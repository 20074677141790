<script lang='ts'>
	import { fade } from 'svelte/transition';

	let className = '';

	let bgClasses = Object.keys($$restProps).filter(value => value.match('bg-')).map(value => `-${value}`).join(' ');
	bgClasses = bgClasses.length === 0 ? '-bg-transparent' : bgClasses;

	export { className as class };
	export let style = null;
</script>

<section class='screen {className} {bgClasses}'
				 {style}
				 in:fade={{delay: 400, duration: 400}} out:fade={{duration: 400}}
				 on:introstart on:introend on:outrostart on:outroend>
	<slot />
</section>

<style lang='scss'>
  .screen {
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    user-select: none;
    overflow-y: auto;
    background: $beige-a;

    &.-bg-gray {
      background: $gray-a;
    }
  }
</style>
