<script lang='ts'>
	export let style = null;

	let className = '';
	export { className as class };

	const filterByTypoClasses = (list: string[]) => {
		return list.filter(typo => ['-.+'].map(value => !!typo.match(value)).includes(true));
	};

	const typoClasses = filterByTypoClasses(Object.keys($$restProps)).join(' ');

	const classes = `${className} ${typoClasses}`.trim();
</script>

<p {style} class='{classes}'>
	<slot />
</p>

<style lang='scss'>
  p {
    width: 100%;
    margin: 0;
    padding: 0;
    color: $black;

    &.-title {
      @include base-font-size(28px);
      line-height: 120%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(42px);
      }
    }

    &.-caption {
      @include base-font-size(18px);
      line-height: 132%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(27px);
      }
    }

    &.-small-text {
      @include base-font-size(12px);
      line-height: 150%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(24px);
      }
    }

    &.-header-title {
      @include base-font-size(18px);
      line-height: 132%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(35px);
        line-height: 120%;
      }
    }

    &.-header-title2 {
      @include base-font-size(24px);
      line-height: 132%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(42px)
      }
    }

    &.-overlay-title {
      @include base-font-size(24px);
      line-height: 103%;
    }

    &.-video-preview-title {
      @include base-font-size(18px);
      line-height: 120%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(42px);
      }
    }

    &.-popup-title {
      @include base-font-size(24px);
      line-height: 120%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(42px);
      }
    }

    &.-popup-caption {
      @include base-font-size(16px);
      line-height: 132%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(28px);
      }
    }

    &.-record-region-task {
      @include base-font-size(16px);
      line-height: 110%;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(28px);
      }
    }

    &.-region-progress {
      @include base-font-size(24px);
      line-height: 132%;
    }

    &.-promocode-value {
      @include base-font-size(36px);
      line-height: 120%;
    }

    &.-menu-title {
      @include base-font-size(22px);
      line-height: 120%;
    }

    &.-cookie {
      font-weight: 400;
      @include base-font-size(12px);
      line-height: 132%;
      color: $gray-b;

      @include breakpoint-up($tablet) {
        @include tablet-font-size(24px);
      }
    }

    &.-bold {
      font-weight: 500;
    }

    &.-center {
      text-align: center;
    }

    &.-white {
      color: $white;
    }

    &.-red {
      color: $red-a;
    }

    &.-beige {
      color: $beige-a;
    }

    &.-gray {
      color: rgba($black, .4);
    }
  }
</style>
