<script lang='ts'>
	import texts from '@/texts';
	import Text from '@/lib/components/typo/Text.svelte';
	import { isOnlineVersion } from '@/stores';

	export let style = null;
</script>

<Text -small-text -gray {style}>
	{@html $isOnlineVersion ? texts.legal.online : texts.legal.offline}
</Text>
