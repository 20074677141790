<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 29 29'>
	<path stroke='#000' stroke-linecap='round' d='M7.071 21.21 21.213 7.07M7.071 7.07l14.142 14.142' />
</svg>

<style lang='scss'>
  svg {
    width: 29px;
    height: 29px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(58px);
      height: px-to-rem-tablet(58px);
    }
  }
</style>
