<script lang='ts'>
	import Popup from '@/lib/components/Popup.svelte';
	import Promocode from '@/lib/components/Promocode.svelte';
	import Button from '@/lib/components/Button.svelte';
	import texts from '@/texts';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import { promoPopupCode } from '@/stores';

	const back = () => {
		eventBus.emit(EVENTS.PROMO_POPUP, false);
	};
	const openApp = () => {
		window.top100Counter.trackEvent('mult_shopping');
		window.open(texts.toAppLink, '_blank');
	};
</script>

<Popup withTappedOverlay blackTappedOverlay>
	<Promocode code='{$promoPopupCode}' style='margin-top: 10px;' />
	<div class='_buttons'>
		<Button -small on:click={openApp}>{@html texts.promo.toApp}</Button>
		<Button on:click={back} -hollow -small>Назад</Button>
	</div>
</Popup>

<style lang='scss'>
  ._buttons {
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
</style>
