<script lang='ts'>
	import { isOnlineVersion, screen } from '@/stores';
	import Screen from '@/lib/components/Screen.svelte';
	import Button from '@/lib/components/Button.svelte';
	import Container from '@/lib/components/Container.svelte';
	import Legal from '@/lib/components/Legal.svelte';
	import MadeByGroznov from '@/lib/components/MadeByGroznov.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Cover from '@/lib/components/Cover.svelte';
	import ActionList from '@/lib/components/ActionList.svelte';
	import Logo from '@/lib/components/Logo.svelte';
</script>

<Screen>
	<Cover />
	<Logo />
	<Container -grow-max>
		<Text -title -bold>{@html texts.main.title}</Text>
		<div class='_caption'>
			<Text -caption>{@html !$isOnlineVersion ? texts.main.captionOffline : texts.main.caption}</Text>
		</div>
		<ActionList />
		<div class='_button'>
			<Button -small on:click={screen.selectVideo}>Выбрать мультфильм</Button>
		</div>
		<div class='_legal'>
			<Legal />
		</div>
		<div class='_groznov'>
			<MadeByGroznov />
		</div>
	</Container>
</Screen>

<style lang='scss'>
  ._caption {
    margin-top: 20px;

    @include breakpoint-up($tablet) {
      width: 800px;
      margin-top: px-to-rem-tablet(31px);
    }
  }

  ._button {
    margin-top: 50px;
    margin-bottom: 50px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(79px);
      margin-bottom: px-to-rem-tablet(79px);
    }
  }

  ._legal {
    margin-top: auto;
  }

  ._groznov {
    margin-top: 1rem;
    margin-bottom: 15px;

    @include breakpoint-up($tablet) {
      margin-bottom: px-to-rem-tablet(50px);
    }
  }
</style>
