<script lang='ts'>
	import { fade } from 'svelte/transition';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Button from '@/lib/components/Button.svelte';
	import { cookie } from '@/stores';

	const accept = () => {
		localStorage.setItem('cookie', 'true');
		cookie.accept();
	};
</script>

<div class='cookie' transition:fade>
	<div class='_text'>
		<Text -cookie>
			{@html texts.cookie}
		</Text>
	</div>
	<div class='_button'>
		<Button -small on:click|once={accept}>ОК</Button>
	</div>
</div>

<style lang='scss'>
  .cookie {
    margin-left: auto;
    margin-right: auto;
    padding: 13px 16px 16px;
    position: fixed;
    left: 13px;
    right: 13px;
    bottom: 20px;
    background: $white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    overflow: hidden;

    @include breakpoint-up($tablet) {
      padding: px-to-rem-tablet(34px) px-to-rem-tablet(36px) px-to-rem-tablet(40px);
      display: flex;
      justify-content: space-between;
    }

    @include desktop-breakpoint-up {
      width: 540px;
      left: auto;
    }
  }

  ._text {
    @include breakpoint-up($tablet) {
      max-width: px-to-rem-tablet(698px);
    }
  }

  ._button {
    margin-top: 20px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(180px);
      margin-top: 0;
    }
  }
</style>
