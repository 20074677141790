<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import VideoPlayer from '@/lib/components/VideoPlayer.svelte';
	import { onDestroy, onMount } from 'svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import type Plyr from 'plyr';
	import { playVideoMenu } from '@/stores.js';
	import PlayVideoMenu from '@/lib/components/menu/PlayVideoMenu.svelte';

	let timeoutId;
	let player: Plyr;
	const menuToggle = () => {
		clearTimeout(timeoutId);
		eventBus.emit(EVENTS.TOGGLE_PLAY_VIDEO_MENU);

		if ($playVideoMenu) timeoutId = setTimeout(() => eventBus.emit(EVENTS.TOGGLE_PLAY_VIDEO_MENU), 3000);
	};

	onMount(() => {
		player.on('ended', () => eventBus.emit(EVENTS.BACK_TO_SELECT_VIDEO));
		eventBus.emit(EVENTS.VIDEO_START);
	});

	onDestroy(() => {
		clearTimeout(timeoutId);
	});
</script>

<Screen bg-gray>
	<VideoPlayer on:click={menuToggle} bind:player={player} final />

	{#if $playVideoMenu}
		<PlayVideoMenu />
	{/if}
</Screen>
