import { get } from 'svelte/store';
import {
	currentVideoIndex,
	currentVideoPart,
	isOnlineVersion,
	promocodes,
	recordedParts,
	savedVideosList,
	sharedVideoLinks,
} from '@/stores';
import videoConfig from '@/video.config';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { uid } from 'uid';
import eventBus from '@/eventBus';
import { EVENTS } from '@/enums';
import lamejs from '@breezystack/lamejs';

export const assetsUrl = (url: string) => `${import.meta.env.VITE_ASSETS_URL}${url}`;

export const getCurrentVideoConfig = () => {
	return videoConfig.video[get(currentVideoIndex)];
};

export const getCurrentRegionData = () => {
	return getCurrentVideoConfig().regions[get(currentVideoPart)];
};

export const getCurrentVideoRecords = () => {
	return get(recordedParts)[get(currentVideoIndex)];
};

export const isVideoRecorded = (index: number) => {
	const regions = get(recordedParts)[index];

	return regions && videoConfig.video[index].regions.length === regions.length;
};

export const recordedVideosLength = () => {
	return videoConfig.video.filter((value, index) => isVideoRecorded(index)).length;
};

export const registerWav = async () => {
	await register(await connect());
};

export const saveCurrentVideoRecord = async (token: string) => {
	const records = getCurrentVideoRecords();
	const resultUrls: string[] = [];

	for (let url of records) {
		try {
			await new Promise<void>(async (resolve, reject) => {
				const blob = await fetch(url).then(b => b.blob());
				const file = new File([blob], `${Date.now()}-${uid(4)}.wav`);

				const signData = await fetch(import.meta.env.VITE_BACKEND_URL + '/api/s3/sign', {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ fileType: 'audio/wav', fileName: file.name }),
				});

				const data = signData.ok && (await signData.json());

				if (data) {
					const { signedRequest, resultUrl } = data;

					resultUrls.push(resultUrl);

					fetch(signedRequest, {
						method: 'PUT',
						body: file,
						headers: {
							'Content-Type': file.type,
							Accept: '*/*',
						},
					})
						.then(value => {
							value.ok && resolve();
							!value.ok && reject();
						})
						.catch(reject);
				}
			});
		} catch (e) {}
	}

	savedVideosList.add(get(currentVideoIndex));
	const promoValue = savedVideosList.getPromoValue();

	const path = get(isOnlineVersion) ? '/api/mult' : '/api/mult/offline';
	const body = get(isOnlineVersion)
		? {
				tracks: resultUrls,
				version: get(currentVideoIndex) + 1,
				token,
				promocodeValue: `${promoValue}%`,
			}
		: {
				tracks: resultUrls,
				version: get(currentVideoIndex) + 1,
			};
	const multData = await fetch(import.meta.env.VITE_BACKEND_URL + path, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

	const data = multData.ok && (await multData.json());

	if (data) {
		const { tracks, promocode, id } = data;

		if (id !== undefined) sharedVideoLinks.add(get(currentVideoIndex), id);
		if (promocode) promocodes.add(promoValue, promocode);
		if (tracks) eventBus.emit(EVENTS.UPDATE_RECORDED_REGIONS, get(currentVideoIndex), tracks);
	}

	return data;
};

export const normalize = (dataArray: Int16Array, maxValue = 0.75) => {
	const sortedDataArray = new Int16Array(dataArray.length);
	sortedDataArray.set(dataArray);
	sortedDataArray.sort((a, b) => (a > b ? -1 : 1));

	const maxDataValue = sortedDataArray[0] / 128.0;
	const multiplyValue = (256 * maxValue) / maxDataValue;

	for (let i = 0; i < dataArray.length; i++)
		dataArray[i] = Math.floor(dataArray[i] * multiplyValue);

	return dataArray;
};

export const convertWavToMp3 = function (wavBlob: Blob): Promise<Blob> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = function () {
			const arrayBuffer = this.result;
			// @ts-ignore
			const wavDecoder = lamejs.WavHeader.readHeader(new DataView(arrayBuffer));
			let wavSamples = new Int16Array(
				arrayBuffer as ArrayBuffer,
				wavDecoder.dataOffset,
				wavDecoder.dataLen / 2,
			);

			wavSamples = normalize(wavSamples);
			const mp3Encoder = new lamejs.Mp3Encoder(wavDecoder.channels, wavDecoder.sampleRate, 128);
			const mp3Buffer = mp3Encoder.encodeBuffer(wavSamples);
			const mp3Data = mp3Encoder.flush();
			const mp3BufferWithHeader = new Uint8Array(mp3Buffer.length + mp3Data.length);

			mp3BufferWithHeader.set(mp3Buffer, 0);
			mp3BufferWithHeader.set(mp3Data, mp3Buffer.length);

			const mp3Blob = new Blob([mp3BufferWithHeader], { type: 'audio/mp3' });

			resolve(mp3Blob);
		};

		reader.onerror = function (error) {
			reject(error);
		};

		reader.readAsArrayBuffer(wavBlob);
	});
};
