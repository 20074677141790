<script lang='ts'>
	import { cookie, menu, overlay, overlayBg, popup, screen } from '@/stores';
	import OverlayBg from '@/lib/components/OverlayBg.svelte';
	import Menu from '@/lib/components/menu/Menu.svelte';
	import Cookie from '@/lib/components/Cookie.svelte';
</script>

{#key $screen}
	<svelte:component this={$screen} />
{/key}

{#if !$cookie}
	<Cookie />
{/if}

{#if $overlayBg}
	<OverlayBg />
{/if}

{#key $overlay}
	<svelte:component this={$overlay} />
{/key}

{#if $menu}
	<Menu />
{/if}

{#if $popup}
	<svelte:component this={$popup} />
{/if}
