<script lang='ts'>
	import { assetsUrl } from '@/utils';

	export let src: string;
	export let alt = null;
	let className = '';

	export { className as class };
</script>

{#if src}
	<img src='{assetsUrl(src)}' {alt} class='{className}'>
{/if}
