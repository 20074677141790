<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 27'>
	<path stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 13 9.692 12L30 2' />
</svg>

<style lang='scss'>
  svg {
    width: 32px;
    height: 27px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(49px);
      height: px-to-rem-tablet(40px);
    }
  }
</style>
