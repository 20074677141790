import eventBus from '@/eventBus';
import { EVENTS } from '@/enums';
import { SvelteComponent } from 'svelte';
import { derived, get, writable } from 'svelte/store';
import SelectVideoComponent from '@/lib/screens/SelectVideo.svelte';
import RecordComponent from '@/lib/screens/Record.svelte';
import MicrophoneTestPopup from '@/lib/components/popup/MicrophoneTestPopup.svelte';
import MicrophoneTestDonePopup from '@/lib/components/popup/MicrophoneTestDonePopup.svelte';
import FinalVideo from '@/lib/screens/FinalVideo.svelte';
import videoConfig from '@/video.config';
import type Plyr from 'plyr';
import Promo from '@/lib/screens/Promo.svelte';
import Start from '@/lib/screens/Start.svelte';
import Countdown1 from '@/assets/Countdown1.svelte';
import Countdown2 from '@/assets/Countdown2.svelte';
import Countdown3 from '@/assets/Countdown3.svelte';
import OfflinePromo from '@/lib/screens/OfflinePromo.svelte';
import PreRecordOverlay from '@/lib/components/PreRecordOverlay.svelte';
import PreWatchFinalOverlay from '@/lib/components/PreWatchFinalOverlay.svelte';
import VideoMenuOverlay from '@/lib/components/menu/VideoMenuOverlay.svelte';
import SharedQR from '@/lib/screens/SharedQR.svelte';
import SharedVideoLanding from '@/lib/screens/SharedVideoLanding.svelte';
import SharedVideo from '@/lib/screens/SharedVideo.svelte';
import MicrophoneTestFailedPopup from '@/lib/components/popup/MicrophoneTestFailedPopup.svelte';
import PlayVideo from '@/lib/screens/PlayVideo.svelte';
import AttentionPopup from '@/lib/components/popup/AttentionPopup.svelte';
import PromoPopup from '@/lib/components/popup/PromoPopup.svelte';
import SharePopup from '@/lib/components/popup/SharePopup.svelte';
import AttentionQRPopup from '@/lib/components/popup/AttentionQRPopup.svelte';
import P404 from '@/lib/screens/404.svelte';
import { Howl, Howler } from 'howler';
import { recordedVideosLength } from '@/utils';

export const screen = (() => {
	const pMatch = location.pathname.match(/\/p\/(?<hash>[a-zA-Z\d]+)/);
	const mMatch = location.pathname.match(/\/m\/(?<video>[a-zA-Z\d]+)/);
	const p404 = location.pathname.match(/\/.+/);
	let startScreen: typeof SvelteComponent | null = pMatch || mMatch ? null : p404 ? P404 : Start;

	if (pMatch) {
		const hash = pMatch.groups?.hash;

		fetch(import.meta.env.VITE_BACKEND_URL + `/api/promocode/${hash}`)
			.then(async res => {
				if (res.ok) {
					const data = await res.json();
					sharedQRData.set(data);
					screen.sharedQR();
				} else {
					screen.P404();
				}
			})
			.catch(() => {
				screen.P404();
			});
	}

	if (mMatch) {
		const video = mMatch.groups?.video;

		fetch(import.meta.env.VITE_BACKEND_URL + `/api/mult/${video}`)
			.then(async res => {
				if (res.ok) {
					const data = await res.json();
					sharedVideoData.set(data);
					screen.sharedVideoLanding();
				} else {
					screen.P404();
				}
			})
			.catch(() => {
				screen.P404();
			});
	}

	const { subscribe, set } = writable(startScreen);

	eventBus.on(EVENTS.SHARED_VIDEO, () => screen.sharedVideo());
	eventBus.on(EVENTS.BACK_TO_SHARED_VIDEO_LANDING, () => screen.sharedVideoLanding());
	eventBus.on(EVENTS.BACK_TO_SELECT_VIDEO, () => screen.selectVideo());
	eventBus.on(EVENTS.RESTART_RECORD, () => screen.record(get(currentVideoIndex)));
	eventBus.on(EVENTS.FINAL_VIDEO, (index: number) => {
		screen.finalVideo(index);
		eventBus.emit(EVENTS.PRE_FINAL_OVERLAY, false);
	});
	eventBus.on(EVENTS.PROMO_PAGE, () =>
		get(isOnlineVersion) ? screen.promo() : screen.offlinePromo(),
	);

	return {
		subscribe,
		selectVideo: () => set(SelectVideoComponent),
		record: (id: number) => {
			eventBus.emit(EVENTS.SELECT_VIDEO, id);
			set(RecordComponent);
		},
		finalVideo: (id: number) => {
			eventBus.emit(EVENTS.SELECT_VIDEO, id);
			set(FinalVideo);
		},
		playVideo: (id: number) => {
			eventBus.emit(EVENTS.SELECT_VIDEO, id);
			set(PlayVideo);
		},
		promo: () => set(Promo),
		offlinePromo: () => set(OfflinePromo),
		sharedVideoLanding: () => {
			set(SharedVideoLanding);
		},
		sharedVideo: () => {
			const { version, tracks } = get(sharedVideoData);
			eventBus.emit(EVENTS.SELECT_VIDEO, version - 1);
			for (let track of tracks) {
				eventBus.emit(EVENTS.ADD_RECORDED_REGION, version - 1, track);
			}
			set(SharedVideo);
		},
		sharedQR: () => {
			set(SharedQR);
		},
		P404: () => set(P404),
	};
})();

export const popup = (() => {
	const { subscribe, set } = writable<typeof SvelteComponent | null>(null);

	eventBus.on(EVENTS.MICROPHONE_TEST_DONE, () => popup.microphoneTestDone());
	eventBus.on(EVENTS.CLOSE_POPUP, () => popup.hide());
	eventBus.on(EVENTS.VIDEO_START, () => popup.hide());
	eventBus.on(EVENTS.RESTART_MICROPHONE_TEST, () => popup.hide());
	eventBus.on(EVENTS.MICROPHONE_TEST_FAILED, () => popup.microphoneTestFailed());
	eventBus.on(EVENTS.ATTENTION_POPUP, state => (state ? popup.attention() : popup.hide()));
	eventBus.on(EVENTS.ATTENTION_QR_POPUP, state => (state ? popup.attentionQR() : popup.hide()));
	eventBus.on(EVENTS.PROMO_POPUP, state => (state ? popup.promo() : popup.hide()));
	eventBus.on(EVENTS.SHARE_POPUP, state => (state ? popup.share() : popup.hide()));

	return {
		subscribe,
		hide: () => set(null),
		microphoneTest: () => set(MicrophoneTestPopup),
		microphoneTestDone: () => set(MicrophoneTestDonePopup),
		microphoneTestFailed: () => set(MicrophoneTestFailedPopup),
		attention: () => set(AttentionPopup),
		attentionQR: () => set(AttentionQRPopup),
		promo: () => set(PromoPopup),
		share: () => set(SharePopup),
	};
})();

export const currentVideoIndex = (() => {
	const { subscribe, set } = writable(0);

	eventBus.on(EVENTS.SELECT_VIDEO, index => set(index));

	return {
		subscribe,
	};
})();

export const currentVideoPart = (() => {
	let initValue = 0;
	const { subscribe, set } = writable(initValue);

	eventBus.on(EVENTS.NEXT_REGION, () => {
		const value = Math.min(
			++initValue,
			videoConfig.video[get(currentVideoIndex)].regions.length - 1,
		);

		set(value);
	});
	eventBus.on(EVENTS.RESTART_RECORD, () => {
		initValue = 0;
		set(initValue);
	});
	eventBus.on(EVENTS.VIDEO_START, () => {
		initValue = 0;
		set(initValue);
	});

	return {
		subscribe,
	};
})();

export const inRecordRegion = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.RESTART_RECORD, () => set(false));
	eventBus.on(EVENTS.IN_RECORD_REGION, val => set(val));

	return {
		subscribe,
	};
})();

export const recordActivated = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.START_RECORD, () => set(true));
	eventBus.on(EVENTS.STOP_RECORD, () => set(false));

	return {
		subscribe,
	};
})();

export const recordInformer = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.START_RECORD, () => set(true));
	eventBus.on(EVENTS.STOP_RECORD, () => set(false));

	return {
		subscribe,
		hide: () => set(false),
	};
})();

export const regionsProgressInformer = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.REGIONS_PROGRESS_INFORMER, val => set(val));

	return {
		subscribe,
	};
})();

export const countdown = (() => {
	let value = 0;
	const assets = [Countdown1, Countdown2, Countdown3];
	const { subscribe, set } = writable<typeof SvelteComponent>(assets[value - 1]);

	const sound = new Howl({
		src: ['/countdown.mp3'],
		preload: true,
		loop: false,
		volume: 0.5,
	});

	eventBus.on(EVENTS.SET_COUNTDOWN, val => {
		if (val !== value) {
			value = val;
			set(assets[value - 1]);
			sound.play();
		}
	});

	return {
		subscribe,
	};
})();

export const countDownInformer = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.COUNTDOWN_INFORMER, val => set(val));

	return {
		subscribe,
	};
})();

export const recordedParts = (() => {
	let value: Record<number, string[]> = {};
	const { subscribe, set } = writable<Record<number, string[]>>(value);

	eventBus.on(EVENTS.ADD_RECORDED_REGION, (index, url) => {
		value[index] = value[index] || [];
		value[index].push(url);

		set(value);
	});

	eventBus.on(EVENTS.UPDATE_RECORDED_REGIONS, (index, urls) => {
		value[index] = value[index] || [];
		value[index] = urls;

		set(value);
	});

	eventBus.on(EVENTS.RESTART_RECORD, () => {
		value[get(currentVideoIndex)] = [];

		set(value);
	});

	return {
		subscribe,
	};
})();

export const currentPlayer = (() => {
	const { subscribe, set } = writable<Plyr>();

	return {
		subscribe,
		set,
	};
})();

export const isOnlineVersion = (() => {
	const { subscribe, set } = writable(!location.host.includes(import.meta.env.VITE_OFFLINE_PREFIX));

	return {
		subscribe,
		set,
	};
})();

export const microphoneTestDone = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.MICROPHONE_TEST_DONE, () => set(true));
	eventBus.on(EVENTS.RESTART_MICROPHONE_TEST, () => set(false));

	return {
		subscribe,
	};
})();

export const menu = (() => {
	const { subscribe, set } = writable(false);

	return {
		subscribe,
		show: () => set(true),
		hide: () => set(false),
	};
})();

export const sharedVideoMenu = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.TOGGLE_SHARED_VIDEO_MENU, () => set(!get(sharedVideoMenu)));
	eventBus.on(EVENTS.BACK_TO_SELECT_VIDEO, () => set(false));

	return {
		subscribe,
	};
})();

export const playVideoMenu = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.TOGGLE_PLAY_VIDEO_MENU, () => set(!get(playVideoMenu)));
	eventBus.on(EVENTS.BACK_TO_SELECT_VIDEO, () => set(false));

	return {
		subscribe,
	};
})();

export const finalVideoMenu = (() => {
	const { subscribe, set } = writable(false);

	eventBus.on(EVENTS.FINAL_VIDEO_MENU, state => set(state));

	return {
		subscribe,
	};
})();

export const overlay = (() => {
	const { subscribe, set } = writable<typeof SvelteComponent | null>(null);

	eventBus.on(EVENTS.PRE_RECORD_OVERLAY, val => set(val ? PreRecordOverlay : null));
	eventBus.on(EVENTS.PRE_FINAL_OVERLAY, val => set(val ? PreWatchFinalOverlay : null));
	eventBus.on(EVENTS.VIDEO_MENU_OVERLAY, val => set(val ? VideoMenuOverlay : null));

	return {
		subscribe,
	};
})();

export const sharedQRData = writable();
export const sharedVideoData = writable<{ version: number; tracks: string[] }>();

export const overlayBg = derived(
	[popup, overlay],
	([$popup, $overlay]) => $popup !== null || $overlay,
);
export const recordingInfoBackground = derived([regionsProgressInformer], stores =>
	stores.includes(true),
);

export const promoValues = writable([10, 15, 20]);

export const savedVideosList = (() => {
	const initValue = <number[]>[];
	const { subscribe, set } = writable<number[]>(initValue);

	return {
		subscribe,
		add: (p: number) => {
			if (!initValue.includes(p)) {
				initValue.push(p);
				set(initValue);
			}
		},
		getPromoValue: () => {
			return get(promoValues)[recordedVideosLength() - 1];
		},
	};
})();

export const promocodes = (() => {
	const { subscribe, set } = writable<Map<number, string>>(new Map());

	return {
		subscribe,
		add: (v: number, p: string) => {
			const promo = get(promocodes);
			promo.set(v, p);

			set(promo);
		},
		getIndex: (code: string) => {
			const promo = get(promocodes);

			return Array.from(promo.values()).indexOf(code);
		},
	};
})();

export const sharedVideoLinks = (() => {
	const { subscribe, set } = writable<Map<number, number>>(new Map());

	return {
		subscribe,
		add: (v: number, p: number) => {
			const promo = get(sharedVideoLinks);
			promo.set(v, p);

			set(promo);
		},
	};
})();

export const promoPopupCode = (() => {
	const { subscribe, set } = writable();

	return {
		subscribe,
		showValue: (val: number) => {
			set(get(promocodes).get(val) ?? null);
		},
	};
})();

export const recordline = (() => {
	const { subscribe, set } = writable(true);

	eventBus.on(EVENTS.PRE_RECORD_OVERLAY, val => {
		set(!val);
	});

	return {
		subscribe,
	};
})();

export const sound = (() => {
	const { subscribe, set } = writable(true);

	eventBus.on(EVENTS.TOGGLE_SOUND, (val: boolean) => {
		Howler.mute(!val);
		set(val);
	});

	return {
		subscribe,
	};
})();

export const cookie = (() => {
	const { subscribe, set } = writable(!!localStorage.getItem('cookie'));

	return {
		subscribe,
		accept: () => set(true),
	};
})();
