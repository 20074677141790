<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 50 50'>
	<path fill='#FF335F' fill-rule='evenodd'
				d='M22.878 13.647c-.38 3.073-.053 7.16 1.113 11.514 1.166 4.352 2.927 8.056 4.793 10.527.934 1.238 1.864 2.125 2.718 2.643.851.516 1.546.622 2.1.474.555-.149 1.104-.589 1.583-1.461.48-.876.842-2.108 1.033-3.647.38-3.073.053-7.161-1.113-11.514-1.167-4.353-2.927-8.057-4.793-10.528-.935-1.238-1.864-2.124-2.718-2.642-.852-.516-1.547-.623-2.101-.474-.554.148-1.103.588-1.582 1.46-.481.876-.843 2.109-1.033 3.648Zm-.72-4.61c.645-1.175 1.57-2.096 2.817-2.43 1.247-.334 2.51 0 3.655.696 1.144.693 2.252 1.787 3.278 3.147 2.056 2.723 3.912 6.677 5.128 11.215 1.216 4.538 1.586 8.891 1.167 12.277-.21 1.69-.622 3.193-1.265 4.364-.645 1.175-1.57 2.096-2.818 2.43-1.247.335-2.509 0-3.655-.695-1.143-.693-2.251-1.788-3.277-3.147-2.056-2.723-3.913-6.677-5.129-11.216-1.216-4.538-1.585-8.89-1.166-12.277.209-1.69.621-3.192 1.264-4.364Z'
				clip-rule='evenodd' />
	<path fill='#FF335F' fill-rule='evenodd'
				d='M24.785 20.72c-.93-.916-1.793-1.157-2.422-.988l-.518-1.932c1.594-.427 3.154.325 4.343 1.495 1.208 1.188 2.196 2.945 2.74 4.976.544 2.03.567 4.046.115 5.68-.445 1.607-1.42 3.038-3.014 3.465l-.517-1.932c.629-.169 1.255-.808 1.604-2.067.34-1.234.348-2.88-.12-4.629-.468-1.747-1.298-3.17-2.21-4.068Z'
				clip-rule='evenodd' />
	<path fill='#FF335F' fill-rule='evenodd'
				d='M23.571 8.59a1 1 0 0 1 .348 1.37l-3.95 6.635c-3.74 6.286-1.561 14.42 4.822 17.994l6.738 3.77a1 1 0 0 1-.977 1.746l-6.738-3.771c-7.365-4.123-9.88-13.509-5.563-20.762l3.95-6.634a1 1 0 0 1 1.37-.348Z'
				clip-rule='evenodd' />
	<path fill='#FF335F' fill-rule='evenodd'
				d='M9.238 22.92c.782-2.059 2.724-3.337 4.643-3.85l2.92-.783a1 1 0 0 1 .518 1.932l-2.92.782c-1.522.408-2.81 1.36-3.292 2.63-.407 1.073-.648 2.54.006 4.98a1 1 0 1 1-1.932.518c-.752-2.807-.514-4.706.057-6.208Z'
				clip-rule='evenodd' />
	<path fill='#FF335F' fill-rule='evenodd'
				d='M12.334 34.48c1.707 1.392 4.029 1.528 5.947 1.014l2.92-.783a1 1 0 1 0-.517-1.932l-2.92.783c-1.522.407-3.112.227-4.165-.632-.89-.726-1.832-1.875-2.486-4.316a1 1 0 1 0-1.932.518c.752 2.807 1.908 4.332 3.153 5.348Z'
				clip-rule='evenodd' />
</svg>

<style lang='scss'>
  svg {
    width: 50px;
    height: 50px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(100px);
      height: px-to-rem-tablet(100px);
    }
  }
</style>
