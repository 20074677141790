<script lang='ts'>
	import Image from '@/lib/components/Image.svelte';

	export let disabled = false;

	export let src: string;
</script>

<div class='menu-preview-item' class:-disabled={disabled} on:click>
	<div class='spacer'></div>
	{#if src}
		<div class='image-slides'>
			<Image {src} />
		</div>
	{/if}
	<div class='content'>
		<slot />
	</div>
</div>

<style lang='scss'>
  .menu-preview-item {
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: $white;
    border: 1px solid $black;
    cursor: pointer;

    .spacer {
      padding-bottom: 100%;
    }

    .image-slides {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
    }

    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :global(img) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
    }

    &.-disabled {
      background-color: $beige-c;
      border: none;
    }
  }
</style>
