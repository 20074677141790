<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import Button from '@/lib/components/Button.svelte';
	import texts from '@/texts';
	import Container from '@/lib/components/Container.svelte';
	import Legal from '@/lib/components/Legal.svelte';
	import MadeByGroznov from '@/lib/components/MadeByGroznov.svelte';
	import QR from '@/lib/components/QR.svelte';
	import Promocode from '@/lib/components/Promocode.svelte';
	import Header from '@/lib/components/Header.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import { onDestroy, onMount } from 'svelte';
	import EventEmitter from '@/lib/partials/eventEmitter';
	import { EVENTS } from '@/enums';
	import eventBus from '@/eventBus';
	import { saveCurrentVideoRecord } from '@/utils';
	import videoConfig from '@/video.config';
	import { get } from 'svelte/store';
	import { currentVideoIndex } from '@/stores';
	import ImageSlides from '@/lib/components/ImageSlides.svelte';

	let code;
	let url;
	let localEventBus;

	const unpipeLocalBus = () => {
		if (localEventBus) eventBus.unpipe(localEventBus);
	};

	const accept = () => {
		unpipeLocalBus();

		localEventBus = new EventEmitter();
		localEventBus.on(EVENTS.ACCEPT_ATTENTION_QR, () => {
			location.reload();
		});

		eventBus.pipe(localEventBus);
		eventBus.emit(EVENTS.ATTENTION_QR_POPUP, true);
	};

	const execute = () => {
		window.grecaptcha.reset();

		window.grecaptcha.ready(() => window.grecaptcha.execute());
	};

	const getCode = async (token) => {
		const data = await saveCurrentVideoRecord(token);

		if (data) {
			code = data.promocode;
			url = import.meta.env.VITE_FRONTEND_URL + `/p/${data.hash}`;
		}
	};

	onMount(async () => {
		getCode('');
	});

	onDestroy(() => {
		unpipeLocalBus();
	});
</script>

<Screen>
	<Header />
	<div class='cover'>
		<ImageSlides images='{videoConfig.video[get(currentVideoIndex)].preview}' />
	</div>
	<Container -grow-max>
		<div class='_title'>
			<Text -title -bold>{@html texts.promo.title}</Text>
		</div>
		<Promocode code='МУЛЬТФИЛЬМ' -no-copy />
		<div class='_qr'>
			<QR {url} />
		</div>
		<div class='_button'>
			<Button -small -hollow on:click={accept}>{@html texts.promo.closeOffline}</Button>
		</div>
		<div class='_legal'>
			<Legal />
		</div>
		<div class='_made-by-groznov'>
			<MadeByGroznov />
		</div>
	</Container>
</Screen>

<style lang='scss'>
  .cover {
    width: 100%;
    height: 229px;
    flex-shrink: 0;
    border: 1px solid $black;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(531px);
    }

    @include desktop-breakpoint-up {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    :global(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ._qr {
    margin-top: 20px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(44px);
    }
  }

  ._title {
    margin-top: 18px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(50px);
    }
  }

  ._button {
    margin-top: 100px;
    margin-bottom: 62px;
  }

  ._legal {
    margin-top: auto;
  }

  ._made-by-groznov {
    margin-top: 1rem;
    margin-bottom: 50px;
  }
</style>
