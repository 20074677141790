<script lang='ts'>
	import { fade } from 'svelte/transition';
	import PopupTop from '@/assets/PopupTop.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';

	let withTappedOverlay = false;
	let blackTappedOverlay = false;
	let className = '';

	export { className as class, withTappedOverlay, blackTappedOverlay };
	export let style = null;
</script>

{#if withTappedOverlay}
	<div class='overlay' class:-black={blackTappedOverlay} on:click={()=>eventBus.emit(EVENTS.CLOSE_POPUP)}></div>
{/if}
<div class='popup {className}' {style} transition:fade>
	<PopupTop />
	<div class='content'>
		<div class='_wrapper'>
			<slot />
		</div>
	</div>
</div>

<style lang='scss'>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.-black {
      background-color: $overlay;
    }
  }

  .popup {
    width: 354px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid $black;
    transform: translateY(-50%);
    overflow: hidden;

    @include breakpoint-up($tablet) {
      width: 617px;
    }

    @include desktop-breakpoint-up {
      width: 308px;
    }

    .content {
      padding: 20px 22px 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      background: $beige-a;

      @include breakpoint-up($tablet) {
        padding: px-to-rem-tablet(30px) px-to-rem-tablet(39px) px-to-rem-tablet(60px);
      }

      &:before {
        width: 100%;
        height: 4px;
        position: absolute;
        top: -2px;
        content: '';
        background: $beige-a;
      }
    }

    ._wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
</style>
