<script lang='ts'>
	import { countdown } from '@/stores';
</script>

<div class='record-part-task-informer'>
	{#key $countdown}
		<div class='countdown'>
			<svelte:component this={$countdown} />
		</div>
	{/key}
</div>

<style lang='scss'>
  .record-part-task-informer {
    margin: 0 auto;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-50%);
  }

  .countdown {
    display: flex;

    :global(svg) {
      width: 100px;

      @include breakpoint-up($tablet) {
        width: px-to-rem-tablet(300px);
      }

      @include desktop-breakpoint-up {
        width: 75px;
      }
    }
  }
</style>
