<script lang='ts'>
	import Popup from '@/lib/components/Popup.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Button from '@/lib/components/Button.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';

	const accept = () => {
		eventBus.emit(EVENTS.ACCEPT_ATTENTION_QR);
		close();
	};

	const close = () => {
		eventBus.emit(EVENTS.ATTENTION_QR_POPUP, false);
	};
</script>

<Popup withTappedOverlay>
	<Text -center -popup-title -bold>{@html texts.popup.attentionQR.title}</Text>
	<div class='_buttons'>
		<Button -small on:click={close}>Ой, Нет</Button>
		<Button -small -hollow on:click={accept}>Да, код отсканирован</Button>
	</div>
</Popup>

<style lang='scss'>
  ._buttons {
    margin-top: 91px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    @include breakpoint-up($tablet) {
      row-gap: 30px;
    }
  }
</style>
