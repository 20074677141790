<script lang='ts'>
	import texts from '@/texts';
	import Text from '@/lib/components/typo/Text.svelte';
	import { isOnlineVersion } from '@/stores';
	import Image from '@/lib/components/Image.svelte';

	const list = $isOnlineVersion ? texts.main.list.online : texts.main.list.offline;
</script>

<div class='action-list'>
	{#each list as { img, text }}
		<div class='action'>
			<Image src='{img}' />
			<div class='_text'>
				<Text -caption -bold>
					{@html text}
				</Text>
			</div>
		</div>
	{/each}
</div>

<style lang='scss'>
  .action-list {
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    row-gap: 31px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(131px);
    }
  }

  .action {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 14px;

    @include breakpoint-up($tablet) {
      column-gap: px-to-rem-tablet(50px);
    }

    :global(img) {
      width: 95px;
      height: 85px;
      object-fit: contain;
      flex-shrink: 0;

      @include breakpoint-up($tablet) {
        width: px-to-rem-tablet(150px);
        height: px-to-rem-tablet(135px);
      }
    }

    ._text {
      @include breakpoint-up($tablet) {
        max-width: 569px;
      }

      @include desktop-breakpoint-up {
        max-width: 284px;
      }
    }
  }
</style>
