export class RMSAnalyzer {
	static readonly fineSignalValue = 0.997;
	private rms = 0;
	private dataArray = new Uint8Array();
	private readonly audioContext!: AudioContext;
	private readonly analyser!: AnalyserNode;

	constructor(stream: MediaStream) {
		this.audioContext = new AudioContext();
		this.analyser = this.audioContext.createAnalyser();
		const microphone = this.audioContext.createMediaStreamSource(stream);

		this.analyser.smoothingTimeConstant = 0.8;
		this.analyser.fftSize = 1024;

		microphone.connect(this.analyser);
	}

	static calculateRMS(dataArray: Uint8Array) {
		const arrayPOW2Sum = dataArray.reduce(
			(a, value) => a + Math.pow(Math.abs(value / 128.0), 2),
			0,
		);

		return Math.sqrt(arrayPOW2Sum / dataArray.length);
	}

	onFineSignal() {
		return new Promise<void>(resolve => {
			let lastFrameTime = performance.now();

			const loop = () => {
				let now = performance.now();
				let delta = now - lastFrameTime;
				lastFrameTime = now;

				this.updateRMS(delta);

				if (this.rms >= RMSAnalyzer.fineSignalValue) resolve();
				else requestAnimationFrame(loop);
			};

			requestAnimationFrame(loop);
		});
	}

	private updateRMS(delta: number) {
		const { sampleRate } = this.audioContext;
		const newSampleLength = sampleRate * (delta / 1000);
		const exampleLength = sampleRate * 1;
		let dataArray = new Uint8Array(this.analyser.frequencyBinCount);
		this.analyser.getByteTimeDomainData(dataArray);

		dataArray = dataArray.slice(-newSampleLength);
		const sumArray = new Uint8Array(this.dataArray.length + dataArray.length);

		sumArray.set(this.dataArray);
		sumArray.set(dataArray, this.dataArray.length);
		this.dataArray = sumArray.slice(-exampleLength);

		this.rms = this.dataArray.length < exampleLength ? 0 : RMSAnalyzer.calculateRMS(this.dataArray);
	}
}
