<script lang='ts'>
	import { onDestroy, onMount } from 'svelte';
	import RecordController from '@/lib/partials/recordController';
	import { recordline } from '@/stores';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import Image from '@/lib/components/Image.svelte';

	let root: HTMLElement;
	let canvas: HTMLCanvasElement;
	let rec: RecordController;
	let baseCanvasH = 131;

	onMount(async () => {
		rec = new RecordController(canvas);
		let desktopMatch = matchMedia('(orientation: landscape) and (min-width: 1001px)').matches;
		let canvasWidth = root.clientWidth;
		let canvasHeight = matchMedia('(min-width: 1001px)').matches ? desktopMatch ? 131 : 229 : baseCanvasH;

		canvas.width = canvasWidth * window.devicePixelRatio;
		canvas.height = canvasHeight * window.devicePixelRatio;
		canvas.style.width = canvasWidth + 'px';
		canvas.style.height = canvasHeight + 'px';

		if (RecordController.stream) {
			await rec.showRecord();
		} else {
			eventBus.once(EVENTS.PERMISSION_ALLOWED, () => {
				rec.showRecord();
			});
		}
	});

	onDestroy(() => {
		rec.removeRecord();
	});
</script>

<div class:-hide={!$recordline} class='record-preview' bind:this={root}>
	<canvas data-base-h='{baseCanvasH*window.devicePixelRatio}' bind:this={canvas}></canvas>
	<Image class='record-line' src='/record-line.png' />
</div>

<style lang='scss'>
  .record-preview {
    height: 250px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.015709) 4.31%, rgba(0, 0, 0, 0.0425) 9.05%, rgba(0, 0, 0, 0.085) 13.11%, rgba(0, 0, 0, 0.153) 16.7%, rgba(0, 0, 0, 0.223244) 19.71%, rgba(0, 0, 0, 0.365194) 26.53%, rgba(0, 0, 0, 0.4675) 32.25%, rgba(0, 0, 0, 0.558527) 37.5%, rgba(0, 0, 0, 0.614844) 41.23%, rgba(0, 0, 0, 0.669196) 45.41%, rgba(0, 0, 0, 0.721707) 50.18%, rgba(0, 0, 0, 0.763802) 55.78%, rgba(0, 0, 0, 0.7905) 60.2%, rgba(0, 0, 0, 0.809401) 65.84%, rgba(0, 0, 0, 0.8245) 72.55%, rgba(0, 0, 0, 0.8415) 81.2%, rgba(0, 0, 0, 0.85) 94.81%);
    box-sizing: border-box;
    opacity: 1;
    transition: opacity .4ms ease;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(340px);
      padding-bottom: px-to-rem-tablet(30px);
    }

    canvas {
      width: 100%;
      height: 131px;
      position: relative;
      display: flex;

      @include breakpoint-up($tablet) {
        height: px-to-rem-tablet(229px);
      }
    }

    :global(.record-line) {
      width: 46px;
      height: 134px;
      position: absolute;
      left: calc(50% - 23px);
      bottom: 20px;

      @include breakpoint-up($tablet) {
        width: px-to-rem-tablet(80px);
        height: px-to-rem-tablet(229px);
        left: calc(50% - px-to-rem-tablet(40px));
        bottom: px-to-rem-tablet(30px);
      }
    }

    &.-hide {
      opacity: 0;
    }
  }
</style>
