<script lang='ts'>
	export let big = false;
</script>

<div class='round-button'>
	<button on:click class:-big={big}>
		<slot />
	</button>

	{#if $$slots.text}
		<div class='text'>
			<slot name='text' />
		</div>
	{/if}
</div>

<style lang='scss'>
  .round-button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    @include reset-button;

    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: px-to-rem-tablet(48px);

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(100px);
      height: px-to-rem-tablet(100px);
      border-radius: px-to-rem-tablet(100px);
    }

    &.-big {
      width: 94px;
      height: 94px;
      border-radius: 94px;
      background: $beige-a;

      @include breakpoint-up($tablet) {
        width: px-to-rem-tablet(165px);
        height: px-to-rem-tablet(165px);
        border-radius: px-to-rem-tablet(165px);
      }
    }
  }

  .text {
    margin-top: 21px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(36px);
    }
  }
</style>
