<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import VideoPlayer from '@/lib/components/VideoPlayer.svelte';
	import { onDestroy, onMount } from 'svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import type Plyr from 'plyr';
	import { sharedVideoMenu } from '@/stores.js';
	import SharedVideoMenu from '@/lib/components/menu/SharedVideoMenu.svelte';

	let timeoutId;
	let player: Plyr;
	const menuToggle = () => {
		clearTimeout(timeoutId);
		eventBus.emit(EVENTS.TOGGLE_SHARED_VIDEO_MENU);
		eventBus.emit($sharedVideoMenu ? EVENTS.VIDEO_RESUME : EVENTS.VIDEO_PAUSE);

		if ($sharedVideoMenu) timeoutId = setTimeout(() => eventBus.emit(EVENTS.TOGGLE_SHARED_VIDEO_MENU), 3000);
	};

	onMount(() => {
		player.on('ended', () => eventBus.emit(EVENTS.BACK_TO_SHARED_VIDEO_LANDING));
		eventBus.emit(EVENTS.VIDEO_START);
	});

	onDestroy(() => {
		clearTimeout(timeoutId);
	});
</script>

<Screen bg-gray>
	<VideoPlayer on:click={menuToggle} bind:player={player} final />

	{#if $sharedVideoMenu}
		<SharedVideoMenu />
	{/if}
</Screen>
