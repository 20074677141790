<script lang='ts'>
	import RoundButton from '@/lib/components/RoundButton.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import SoundOn from '@/assets/SoundOn.svelte';
	import { get } from 'svelte/store';
	import { sound } from '@/stores';
	import SoundOff from '@/assets/SoundOff.svelte';

	const toggleSound = () => eventBus.emit(EVENTS.TOGGLE_SOUND, !get(sound));
</script>

<RoundButton on:click={toggleSound}>
	{#if $sound}
		<SoundOn />
	{:else}
		<SoundOff />
	{/if}
</RoundButton>
