<script lang='ts'>
	import Popup from '@/lib/components/Popup.svelte';
	import Button from '@/lib/components/Button.svelte';
	import Countdown from '@/assets/Countdown.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
</script>

<Popup class='microphone-test-done'>
	<div class='_countdown'>
		<Countdown />
	</div>
	<div class='_text'>
		<Text -popup-caption -center>
			{@html texts.popup.testDone.caption}
		</Text>
	</div>
	<div class='_button'>
		<Button -small on:click={()=>eventBus.emit(EVENTS.VIDEO_START)}>
			Смотреть
		</Button>
	</div>
</Popup>

<style lang='scss'>
  ._countdown {
    height: 153px;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(268px);
    }
  }

  ._text {
    margin-top: 25px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(42px);
    }
  }

  ._button {
    margin-top: 36px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(62px);
      margin-bottom: px-to-rem-tablet(7px);
    }
  }
</style>
