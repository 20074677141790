<script lang='ts'>
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Container from '@/lib/components/Container.svelte';
	import RoundButton from '@/lib/components/RoundButton.svelte';
	import Triangle2 from '@/assets/Triangle2.svelte';
	import { currentVideoIndex, isOnlineVersion } from '@/stores';
	import { get } from 'svelte/store';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import { recordedVideosLength } from '@/utils';
</script>

<div class='pre-watch-final-video'>
	<div class='_text'>
		<Container>
			<Text -popup-title -white -center>
				{#if $isOnlineVersion}
					{@html texts.finalVideo.titles[recordedVideosLength() - 1]}
				{:else}
					{@html texts.finalVideo.offlineTitle}
				{/if}
			</Text>
		</Container>
	</div>

	<button class='_button' on:click={()=>eventBus.emit(EVENTS.FINAL_VIDEO, get(currentVideoIndex))}>
		<RoundButton big>
			<Triangle2 />
			<span slot='text'>
				<Text -header-title2 -white -center>
				{@html texts.finalVideo.watchButton}
				</Text>
			</span>
		</RoundButton>
	</button>
</div>

<style lang='scss'>
  .pre-watch-final-video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ._text {
    position: absolute;
    top: 18%;
  }

  ._button {
    @include reset-button;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
