<script lang='ts'>
	import { fade } from 'svelte/transition';
	import Header from '@/lib/components/Header.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import { get } from 'svelte/store';
	import videoConfig from '@/video.config';
	import { currentVideoIndex, recordedParts } from '@/stores';
	import RoundButton from '@/lib/components/RoundButton.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import ArrowBack from '@/assets/ArrowBack.svelte';
	import Restart from '@/assets/Restart.svelte';
	import Triangle2 from '@/assets/Triangle2.svelte';
	import { onDestroy } from 'svelte';
	import EventEmitter from '@/lib/partials/eventEmitter';

	let localEventBus;

	const unpipeLocalBus = () => {
		if (localEventBus) eventBus.unpipe(localEventBus);
	};

	const hide = () => {
		eventBus.emit(EVENTS.VIDEO_RESUME);
		eventBus.emit(EVENTS.VIDEO_MENU_OVERLAY, false);
	};
	const back = () => {
		unpipeLocalBus();

		localEventBus = new EventEmitter();
		localEventBus.on(EVENTS.ACCEPT_ATTENTION, () => {
			eventBus.emit(EVENTS.BACK_TO_SELECT_VIDEO);
			eventBus.emit(EVENTS.VIDEO_MENU_OVERLAY, false);
		});

		eventBus.pipe(localEventBus);
		eventBus.emit(EVENTS.ATTENTION_POPUP, true);
	};
	const restart = () => {
		unpipeLocalBus();

		localEventBus = new EventEmitter();
		localEventBus.on(EVENTS.ACCEPT_ATTENTION, () => {
			eventBus.emit(EVENTS.RESTART_RECORD);
			eventBus.emit(EVENTS.VIDEO_MENU_OVERLAY, false);
		});

		eventBus.pipe(localEventBus);
		eventBus.emit(EVENTS.ATTENTION_POPUP, true);
	};

	onDestroy(() => {
		unpipeLocalBus();
	});
</script>

<div class='video-menu' transition:fade>
	<Header />
	<div class='_title'>
		<Text -header-title2 -white -center>Пауза</Text>
	</div>

	<div class='buttons'>
		<div class='_button' on:click={back}>
			<RoundButton big>
				<ArrowBack />
				<Text slot='text' -header-title2 -white -center -bold>Выйти</Text>
			</RoundButton>
		</div>
		<div class='_button' on:click={restart}>
			<RoundButton big>
				<Restart />
				<Text slot='text' -header-title2 -white -center -bold>Заново</Text>
			</RoundButton>
		</div>
		<div class='_button' on:click={hide}>
			<RoundButton big>
				<Triangle2 />
				<Text slot='text' -header-title2 -white -center -bold>Продолжить</Text>
			</RoundButton>
		</div>
	</div>
	<div class='progress'>
		{#each videoConfig.video[get(currentVideoIndex)].regions as region, index}
			<div class='region'
					 class:-filled={$recordedParts[$currentVideoIndex] && index < $recordedParts[$currentVideoIndex].length}></div>
		{/each}
	</div>
</div>

<style lang='scss'>
  .video-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ._title {
    margin-top: 31px;
  }

  .buttons {
    position: absolute;
    top: 16%;
    left: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 55px;
  }

  ._button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .progress {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 69px;
    display: flex;
    column-gap: 3px;
  }

  .region {
    width: 100%;
    height: 11px;
    background: rgba($white, .35);
    border-radius: 4px;

    &.-filled {
      background: $white;
    }
  }
</style>
