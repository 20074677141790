<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 33 37'>
	<path stroke='#FF335F' stroke-linecap='round' stroke-width='2'
				d='m9.473 17.809 13.981-3.747M10.93 23.246 24.912 19.5M12.387 28.684l13.982-3.747' />
	<rect width='21.746' height='25.342' x='3.721' y='10.395' stroke='#FF335F' stroke-width='2' rx='3'
				transform='rotate(-15 3.72 10.395)' />
	<path stroke='#FF335F' stroke-linecap='round' stroke-width='2'
				d='M21.89 5.318 3.743 10.181a3 3 0 0 0-2.12 3.674l5.112 19.083a3 3 0 0 0 3.675 2.121l18.146-4.862' />
	<path fill='#FF335F'
				d='M8.183 12.123a1 1 0 0 0 .518 1.932l-.518-1.931ZM8.21 8.99c.259.967.3 1.843.196 2.461-.12.714-.341.706-.222.674l.518 1.931c1.1-.294 1.531-1.41 1.676-2.273.162-.96.08-2.132-.236-3.31l-1.932.517Zm-1.615.433c-.259-.967-.3-1.843-.196-2.461.12-.714.342-.705.222-.673l-.518-1.932c-1.099.294-1.531 1.41-1.676 2.274-.161.96-.08 2.131.237 3.31l1.931-.518Zm.026-3.134c-.12.032.067-.086.528.472.399.483.802 1.263 1.06 2.23l1.933-.518c-.316-1.179-.83-2.235-1.45-2.985C8.133 4.81 7.2 4.06 6.102 4.356l.518 1.932ZM14.568 10.413a1 1 0 0 0 .518 1.931l-.518-1.931Zm.026-3.135c.258.967.3 1.844.196 2.461-.12.714-.342.706-.222.674l.518 1.931c1.099-.294 1.531-1.41 1.676-2.273.161-.96.08-2.132-.237-3.31l-1.931.517Zm-1.615.433c-.259-.967-.3-1.843-.196-2.461.12-.714.341-.705.222-.673l-.518-1.932c-1.1.294-1.531 1.41-1.676 2.274-.162.96-.08 2.131.236 3.31l1.932-.518Zm.026-3.134c-.12.032.067-.086.528.472.399.483.802 1.263 1.06 2.23l1.932-.518c-.315-1.179-.83-2.234-1.45-2.985-.557-.676-1.489-1.426-2.588-1.131l.518 1.932ZM20.951 8.702a1 1 0 0 0 .518 1.931l-.518-1.931Zm.025-3.135c.26.967.3 1.844.197 2.461-.12.714-.342.706-.222.674l.518 1.931c1.099-.294 1.53-1.41 1.676-2.273.161-.96.079-2.132-.237-3.31l-1.932.517ZM19.362 6c-.259-.967-.3-1.843-.196-2.46.12-.715.341-.706.221-.674L18.87.934c-1.1.294-1.532 1.41-1.677 2.274-.16.96-.079 2.131.237 3.31L19.362 6Zm.025-3.134c-.12.032.068-.086.529.472.399.483.801 1.263 1.06 2.23l1.932-.518c-.316-1.179-.83-2.234-1.45-2.985-.557-.675-1.49-1.426-2.588-1.131l.517 1.932Z' />
</svg>

<style lang='scss'>
  svg {
    width: 33px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(54px);
    }
  }
</style>
