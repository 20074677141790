<script lang='ts'>
	import { fade } from 'svelte/transition';
	import RoundButton from '@/lib/components/RoundButton.svelte';
	import texts from '@/texts';
	import Restart from '@/assets/Restart.svelte';
	import Done from '@/assets/Done.svelte';
	import Container from '@/lib/components/Container.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import Text from '@/lib/components/typo/Text.svelte';

	const restart = () => {
		eventBus.emit(EVENTS.VIDEO_PAUSE);
		eventBus.emit(EVENTS.RESTART_RECORD);
	};
</script>

<div class='final-video-menu' transition:fade>
	<div style='margin-top: auto;'>
		<Container>
			<div class='buttons'>
				<button class='_button' on:click={restart}>
					<RoundButton big>
						<Restart />
						<Text -title -white slot='text'>
							{@html texts.finalVideo.menu.cancel}
						</Text>
					</RoundButton>
				</button>
				<button class='_button' on:click={()=>eventBus.emit(EVENTS.PROMO_PAGE)}>
					<RoundButton big>
						<Done />
						<Text -title -white slot='text'>
							{@html texts.finalVideo.menu.accept}
						</Text>
					</RoundButton>
				</button>
			</div>
		</Container>
	</div>
</div>

<style lang='scss'>
  .final-video-menu {
    height: 250px;
    padding-bottom: 46px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.015709) 4.31%, rgba(0, 0, 0, 0.0425) 9.05%, rgba(0, 0, 0, 0.085) 13.11%, rgba(0, 0, 0, 0.153) 16.7%, rgba(0, 0, 0, 0.223244) 19.71%, rgba(0, 0, 0, 0.365194) 26.53%, rgba(0, 0, 0, 0.4675) 32.25%, rgba(0, 0, 0, 0.558527) 37.5%, rgba(0, 0, 0, 0.614844) 41.23%, rgba(0, 0, 0, 0.669196) 45.41%, rgba(0, 0, 0, 0.721707) 50.18%, rgba(0, 0, 0, 0.763802) 55.78%, rgba(0, 0, 0, 0.7905) 60.2%, rgba(0, 0, 0, 0.809401) 65.84%, rgba(0, 0, 0, 0.8245) 72.55%, rgba(0, 0, 0, 0.8415) 81.2%, rgba(0, 0, 0, 0.85) 94.81%);
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      height: 500px;
      padding-bottom: 54px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      column-gap: 20%;
    }

    ._button {
      @include reset-button;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $red-a;

      @include breakpoint-up($tablet) {
        width: 200px;
      }
    }
  }
</style>
