import { get } from 'svelte/store';
import { currentPlayer, currentVideoIndex, currentVideoPart } from '@/stores';
import eventBus from '@/eventBus';
import { EVENTS } from '@/enums';
import EventEmitter from '@/lib/partials/eventEmitter';
import { MediaRecorder } from 'extendable-media-recorder';
import { RMSAnalyzer } from '@/lib/partials/RMSAnalyzer';
import { getCurrentVideoConfig } from '@/utils';

export default class RecordController {
	static stream: MediaStream;
	private readonly ctx: CanvasRenderingContext2D;
	private audioContext!: AudioContext;
	private analyser!: AnalyserNode;
	private analyzerStopCallback!: () => void;
	private recordStopCallback!: () => void;
	private dataArray!: Uint8Array;
	private regionsRecordData!: Uint8Array[];
	private mediaRecorder!: MediaRecorder;
	private readonly recordLineScale = 200;
	private eventBus!: EventEmitter;

	constructor(private readonly canvas: HTMLCanvasElement) {
		this.ctx = <CanvasRenderingContext2D>canvas.getContext('2d');
	}

	static startLoop(callback: (deltaTime: number, totalTimeInLoop: number) => void) {
		let canceled = false;
		let rafID: number;
		let lastFrameTime: number;
		let startTime = performance.now();
		let loop = () => {
			let now = performance.now();
			let delta = now - (lastFrameTime || 0);
			let totalTimeInLoop = (now - startTime) / 1000;
			callback && callback(delta, totalTimeInLoop);

			if (canceled) {
				return cancelAnimationFrame(rafID);
			}

			lastFrameTime = now;
			rafID = requestAnimationFrame(loop);
		};

		rafID = requestAnimationFrame(loop);

		return () => {
			canceled = true;
			cancelAnimationFrame(rafID);
		};
	}

	static getUserMedia = async () => {
		return navigator.mediaDevices
			.getUserMedia({
				audio: {
					echoCancellation: true,
				},
				video: false,
			})
			.then(stream => {
				RecordController.stream = stream;
			});
	};

	async showAnalyser() {
		if (!this.audioContext) await this.createContext();

		this.removeAnalyser();
		this.analyzerStopCallback = RecordController.startLoop(this.updateAnalyzeWave.bind(this));
	}

	async showRecord() {
		if (!this.audioContext) await this.createContext();

		this.regionsRecordData = [];
		this.regionsRecordData[get(currentVideoPart)] = new Uint8Array();
		const player = get(currentPlayer);

		if (!player) return;

		this.removeRecord();
		await this.createRecorder();

		this.recordStopCallback = RecordController.startLoop((deltaTime, time) => {
			this.clearCanvas();

			const progress = player.currentTime / player.duration;

			this.updateVideoRecordRegions(progress, player.duration);

			if (this.isRecording()) {
				this.analyser.getByteTimeDomainData(this.dataArray);

				const regionRecordData = this.regionsRecordData[get(currentVideoPart)] ?? new Uint8Array();
				const lastFrameSampleLength = this.audioContext.sampleRate * (deltaTime / 1000);
				const dataArray =
					deltaTime > 0 ? this.dataArray.slice(-lastFrameSampleLength) : this.dataArray;
				const globalData = new Uint8Array(regionRecordData.length + dataArray.length);

				globalData.set(regionRecordData);
				globalData.set(dataArray, regionRecordData.length);

				this.regionsRecordData[get(currentVideoPart)] = globalData;
			}

			this.updateRecordWave(progress, player.duration);
		});
	}

	removeAnalyser() {
		this.analyzerStopCallback && this.analyzerStopCallback();
	}

	removeRecord() {
		this.recordStopCallback && this.recordStopCallback();
		this.eventBus && eventBus.unpipe(this.eventBus);
	}

	isRecording() {
		return this.mediaRecorder ? this.mediaRecorder.state === 'recording' : false;
	}

	private async createContext() {
		if (this.audioContext) await this.audioContext.close();

		this.audioContext = new AudioContext();
		this.analyser = this.audioContext.createAnalyser();
		this.analyser.fftSize = 2048;
		this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
		this.audioContext.createMediaStreamSource(RecordController.stream).connect(this.analyser);
	}

	private startRecording() {
		this.mediaRecorder.start();
	}

	private stopRecording(): Promise<string> {
		return new Promise((resolve, reject) => {
			if (!this.mediaRecorder) reject();

			this.mediaRecorder.ondataavailable = async e => {
				resolve(URL.createObjectURL(e.data));

				// if ('Worker' in window) {
				// 	const worker: Worker = new Mp3ConverterWorker();
				//
				// 	worker.onmessage = e => {
				// 		worker.terminate();
				// 		resolve(URL.createObjectURL(e.data));
				// 	};
				//
				// 	worker.postMessage(e.data);
				// } else {
				// 	const mp3Blob = await convertWavToMp3(e.data);
				// 	resolve(URL.createObjectURL(mp3Blob));
				// }
			};

			this.mediaRecorder.stop();
		});
	}

	private updateAnalyzeWave() {
		if (!this.ctx) return;

		this.analyser.getByteTimeDomainData(this.dataArray);

		this.drawAnalyzeWave(this.ctx, this.dataArray);
	}

	private updateVideoRecordRegions(progress: number, duration: number) {
		if (!this.ctx) return;

		const { width, height } = this.canvas;
		const baseHeight = this.canvas.getAttribute('data-base-h') ?? '1';
		const widthScale = (height / parseInt(baseHeight)) * window.devicePixelRatio;
		const barWidth = Math.floor(8 * widthScale);
		const dashWidth = Math.floor(16 * widthScale);
		const dashLineWidth = Math.floor(3 * widthScale);
		const xOffset = duration * progress * this.recordLineScale;

		this.ctx.beginPath();
		this.ctx.strokeStyle = '#A8A8A8';
		this.ctx.lineCap = 'butt';
		this.ctx.lineWidth = dashLineWidth;
		this.ctx.setLineDash([]);
		this.ctx.moveTo(-xOffset, height / 2);
		this.ctx.lineTo((duration + width) * this.recordLineScale - xOffset + width, height / 2);
		this.ctx.stroke();

		this.ctx.beginPath();
		this.ctx.strokeStyle = '#FFF';
		this.ctx.lineCap = 'butt';
		this.ctx.lineWidth = dashLineWidth;
		this.ctx.setLineDash([dashWidth, dashWidth]);
		this.ctx.moveTo(-xOffset, height / 2);
		this.ctx.lineTo((duration + width) * this.recordLineScale - xOffset + width, height / 2);
		this.ctx.stroke();

		this.ctx.lineCap = 'round';
		this.ctx.setLineDash([]);

		for (let region of getCurrentVideoConfig().regions) {
			const { start, finish } = region;

			const startPos = width / 2 + start * this.recordLineScale - xOffset;
			const finishPos = width / 2 + finish * this.recordLineScale - xOffset;

			if (finishPos < width / 2) continue;

			this.ctx.beginPath();
			this.ctx.strokeStyle = '#000';
			this.ctx.lineWidth = barWidth + 2;
			this.ctx.moveTo(Math.max(startPos, width / 2), height / 2);
			this.ctx.lineTo(Math.max(finishPos, width / 2), height / 2);
			this.ctx.stroke();

			this.ctx.beginPath();
			this.ctx.strokeStyle = '#FF335F';
			this.ctx.lineWidth = barWidth;
			this.ctx.moveTo(Math.max(startPos, width / 2), height / 2);
			this.ctx.lineTo(Math.max(finishPos, width / 2), height / 2);
			this.ctx.stroke();
		}
	}

	private updateRecordWave(progress: number, duration: number) {
		if (!this.ctx) return;

		this.drawRecordWave(this.ctx, this.regionsRecordData, progress, duration);
	}

	private drawAnalyzeWave(ctx: CanvasRenderingContext2D, dataArray: Uint8Array) {
		const { width, height } = this.canvas;
		const baseHeight = this.canvas.getAttribute('data-base-h') ?? '1';
		const widthScale = (height / parseInt(baseHeight)) * window.devicePixelRatio;
		const barWidth = Math.floor(8 * widthScale);
		const barGap = Math.floor(2 * widthScale);

		ctx.strokeStyle = '#FF335F';
		ctx.lineCap = 'round';
		ctx.lineWidth = barWidth;
		ctx.clearRect(0, 0, width, height);
		ctx.beginPath();

		let x = barWidth / 2;

		const bufferLength = dataArray.length;
		const slicedWidth = Math.floor(width / (barWidth + barGap));
		const slicedBuffer = Math.floor(bufferLength / slicedWidth);

		for (let i = 0; i < bufferLength; i += slicedBuffer) {
			let v = Math.abs(dataArray[i] / 128.0 - 1);
			v = v < 0.03 ? 0 : v;
			const value = Math.max(v * height, 0.01);

			ctx.moveTo(x, height / 2 - value / 2);
			ctx.lineTo(x, height / 2 + value / 2);

			x += barWidth + barGap;
		}

		ctx.stroke();
	}

	private drawRecordWave(
		ctx: CanvasRenderingContext2D,
		regionsData: Uint8Array[],
		progress: number,
		duration: number,
	) {
		const { width, height } = this.canvas;
		const baseHeight = this.canvas.getAttribute('data-base-h') ?? '1';
		const widthScale = (height / parseInt(baseHeight)) * window.devicePixelRatio;
		const barWidth = Math.floor(8 * widthScale);
		const barGap = Math.floor(2 * widthScale);
		const xOffset = duration * progress * this.recordLineScale;

		ctx.lineCap = 'round';

		for (let [index, region] of getCurrentVideoConfig().regions.entries()) {
			if (!regionsData[index]) continue;

			const { start, finish } = region;
			const regionDuration = finish - start;
			const scaledRegionDuration = regionDuration * this.recordLineScale;
			const barsInRegion = Math.floor(scaledRegionDuration / (barWidth + barGap));
			const regionSamples = regionDuration * this.audioContext.sampleRate;
			const slicedBuffer = Math.floor(regionSamples / barsInRegion);

			let x = width / 2;

			for (let i = 0; i < regionsData[index].length; i += slicedBuffer) {
				const v = Math.abs(regionsData[index][i] / 128.0 - 1);
				const value = v * height;

				ctx.beginPath();
				ctx.strokeStyle = '#000';
				ctx.lineWidth = barWidth + 2;
				ctx.moveTo(x + start * this.recordLineScale - xOffset, height / 2 - value / 2);
				ctx.lineTo(x + start * this.recordLineScale - xOffset, height / 2 + value / 2);
				ctx.stroke();

				ctx.beginPath();
				ctx.strokeStyle = '#FF335F';
				ctx.lineWidth = barWidth;
				ctx.moveTo(x + start * this.recordLineScale - xOffset, height / 2 - value / 2);
				ctx.lineTo(x + start * this.recordLineScale - xOffset, height / 2 + value / 2);
				ctx.stroke();

				x += barWidth + barGap;
			}
		}
	}

	private clearCanvas() {
		const { width, height } = this.canvas;
		this.ctx?.clearRect(0, 0, width, height);
	}

	private async createRecorder() {
		if (this.mediaRecorder) await this.stopRecording();

		// @ts-ignore
		this.mediaRecorder = new MediaRecorder(RecordController.stream, {
			mimeType: 'audio/wav',
		});
		this.eventBus = new EventEmitter();

		eventBus.pipe(this.eventBus);

		this.eventBus.on(EVENTS.START_RECORD, () => this.startRecording());
		this.eventBus.on(EVENTS.STOP_RECORD, async () => {
			const index = get(currentVideoIndex);
			const url = await this.stopRecording();
			eventBus.emit(EVENTS.ADD_RECORDED_REGION, index, url);
		});
		this.eventBus.on(EVENTS.TEST_LAST_RECORD, async () => {
			const isFineSignalValue =
				RMSAnalyzer.calculateRMS(this.regionsRecordData[get(currentVideoPart)]) >= 0.997;

			// if (isFineSignalValue) {
			eventBus.emit(EVENTS.REGIONS_PROGRESS_INFORMER, true);
			// } else {
			// 	eventBus.emit(EVENTS.VIDEO_PAUSE);
			// 	eventBus.emit(EVENTS.MICROPHONE_TEST_FAILED);
			// }
		});
	}
}
