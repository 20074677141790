<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 30 34'>
    <path stroke='#FF335F' stroke-linecap='round' stroke-width='3' d='M15 25V2' />
    <path stroke='#FF335F' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'
          d='M23.643 10.357 15.215 1.93l-8.428 8.427' />
    <path stroke='#FF335F' stroke-width='3'
          d='M10.5 16H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h20a3 3 0 0 0 3-3V19a3 3 0 0 0-3-3h-5.5' />
</svg>

<style lang='scss'>
    svg {
        width: 30px;
        height: 34px;
    }
</style>
