<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import Container from '@/lib/components/Container.svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Legal from '@/lib/components/Legal.svelte';
	import Button from '@/lib/components/Button.svelte';
	import Header from '@/lib/components/Header.svelte';
	import Promocode from '@/lib/components/Promocode.svelte';
	import ShareLink from '@/lib/components/ShareLink.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import { onMount } from 'svelte';
	import { recordedVideosLength, saveCurrentVideoRecord } from '@/utils';
	import MadeByGroznov from '@/lib/components/MadeByGroznov.svelte';
	import ImageSlides from '@/lib/components/ImageSlides.svelte';
	import videoConfig from '@/video.config';
	import { get } from 'svelte/store';
	import { currentVideoIndex } from '@/stores';

	let code;
	let id;

	const execute = () => {
		window.grecaptcha.reset();
		window.grecaptcha.ready(() => window.grecaptcha.execute());
	};

	const getCode = async (token) => {
		console.log(token);
		const data = await saveCurrentVideoRecord(token);

		if (data) {
			code = data.promocode;
			id = data.id;
		}
	};

	const openApp = () => {
		window.top100Counter.trackEvent('mult_shopping');
		window.open(texts.toAppLink, '_blank');
	};

	const nextRecord = () => {
		window.top100Counter.trackEvent('mult_next');
		eventBus.emit(EVENTS.BACK_TO_SELECT_VIDEO);
	};

	onMount(async () => {
		window.getCode = getCode;
		execute();
	});
</script>

<Screen>
	<Header />
	<div class='cover'>
		<ImageSlides images='{videoConfig.video[get(currentVideoIndex)].preview}' />
	</div>
	<Container -grow-max>
		<div class='_title'>
			<Text -popup-title -bold>
				{@html texts.promo.titles[recordedVideosLength() - 1]}
			</Text>
		</div>
		<Promocode {code} />
		<div class='_button'>
			{#if recordedVideosLength() === 3}
				<Button -small on:click={openApp}>{@html texts.promo.toApp}</Button>
			{:else}
				<Button -small on:click={nextRecord}>{@html texts.promo.next}</Button>
			{/if}
		</div>
		<div class='_share'>
			<ShareLink videoID='{id}' />
		</div>
		<div class='_legal'>
			<Legal />
		</div>
		<div class='_made-by-groznov'>
			<MadeByGroznov />
		</div>
	</Container>
</Screen>

<style lang='scss'>
  ._title {
    margin-top: 18px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(50px);
    }
  }

  ._legal {
    margin-top: auto;
  }

  ._made-by-groznov {
    margin-top: 1rem;
    margin-bottom: 18px;

    @include breakpoint-up($tablet) {
      margin-bottom: px-to-rem-tablet(50px);
    }
  }

  ._share {
    margin-top: 47px;
    margin-bottom: 39px;
  }

  ._button {
    margin-top: 47px;
  }

  ._button2 {
    margin-top: 15px;
  }

  .cover {
    width: 100%;
    height: 229px;
    flex-shrink: 0;
    border: 1px solid $black;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      height: px-to-rem-tablet(531px);
    }

    @include desktop-breakpoint-up {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    :global(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
