import './app.scss';
import App from './App.svelte';
import { registerWav } from '@/utils';

registerWav();

const app = new App({
	target: document.getElementById('app')!,
});

export default app;
