<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 30 34'>
	<path fill='#FF335F' d='M29.428 17 .928 33.455V.545L29.428 17Z' />
</svg>

<style lang='scss'>
  svg {
    width: 30px;
    height: 34px;

    @include breakpoint-up($tablet) {
      width: px-to-rem-tablet(70px);
      height: px-to-rem-tablet(70px);
    }
  }
</style>
