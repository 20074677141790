<script lang='ts'>
	import { afterUpdate, tick } from 'svelte';
	import * as QRCode from 'qrcode';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';

	export let url;
	let canvas;

	afterUpdate(async () => {
		if (url) {
			await tick();
			QRCode.toCanvas(canvas, url, {
				width: 300,
				margin: 8,
				color: {
					dark: '#FF335F',
				},
			});
		}
	});
</script>

<div class='_text'>
	<Text -header-title2 -bold>{@html texts.promo.shareOffline}</Text>
</div>
<div class='_canvas'>
	{#if url}
		<canvas bind:this={canvas} width='300' height='300'></canvas>
	{:else}
		<Text -title -bold -center -red>Загрузка...</Text>
	{/if}
</div>

<style lang='scss'>
  ._text {
    margin-bottom: 44px;
  }

  ._canvas {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    background: $white;
    overflow: hidden;

    canvas {
      width: 300px !important;
      height: 300px !important;
    }
  }
</style>
